import { useAssetComponentsForms } from '@flyward/assets/context'
import { StandaloneEngineDtoSchema, type StandAloneEngineDTO } from '../../../models'
import { StandaloneEngineReadonlyDetails, StandaloneEngineEditableDetails } from './TechSpecs/EngineDetailsTabs/EditStandaloneEngine'
import { useEffect, useRef } from 'react'
import { useUpdateStandAloneEngineMutation } from '@flyward/assets/store'
import { zodResolver } from '@hookform/resolvers/zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { CnForm } from '@flyward/platform'
import { isNil } from 'lodash'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'

interface IStandAloneEngineDetailsProps {
  assetId: string
  standAloneEngine: StandAloneEngineDTO
}

export const StandAloneEngineDetails = ({
  standAloneEngine,
  assetId,
}: IStandAloneEngineDetailsProps): React.ReactElement<IStandAloneEngineDetailsProps> => {
  const { isInEditMode, registerFormRef, setHasErrors } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const [editStandaloneEngine] = useUpdateStandAloneEngineMutation()

  const form = useForm<StandAloneEngineDTO>({
    defaultValues: standAloneEngine,
    values: standAloneEngine,
    resolver: zodResolver(StandaloneEngineDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleStandaloneEngineUpdate,
    formState,
    getValues: getStandaloneEngineFormValues,
    setValue: setStandaloneEngineFormValue,
    control: standaloneEngineFormControl,
    trigger: triggerStandaloneEngineValidation,
    watch: watchStandaloneEngineFields,
  } = form

  const standaloneEngineFormValues: StandAloneEngineDTO = getStandaloneEngineFormValues()

  const { isDirty, isValid } = formState

  useEffect(() => {
    triggerStandaloneEngineValidation()
  }, [isValid, triggerStandaloneEngineValidation])

  useEffect(() => {
    registerFormRef({ id: [assetId, 'standAloneEngineDetails'], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, standaloneEngineFormValues, isDirty, isValid, assetId])

  const onUpdateStandaloneEngine: SubmitHandler<StandAloneEngineDTO> = async (data) => {
    const result = await editStandaloneEngine({ assetId, standaloneEngine: data })
    if (!isNil(result.error)) {
      setHasErrors(true)
      showError(formatAxiosErrorMessage(result.error?.message))
    } else {
      setHasErrors(false)
      showSuccess('Standalone engine updated successfully')
    }
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleStandaloneEngineUpdate(onUpdateStandaloneEngine)}>
        {isInEditMode ? (
          <StandaloneEngineEditableDetails
            formControl={standaloneEngineFormControl}
            formState={formState}
            watchFields={watchStandaloneEngineFields}
            setFormValue={setStandaloneEngineFormValue}
            getStandaloneEngineFormValues={getStandaloneEngineFormValues}
          />
        ) : (
          <StandaloneEngineReadonlyDetails standAloneEngine={standaloneEngineFormValues} />
        )}
        <input type="submit" className="hidden" ref={submitRef} />
      </form>
    </CnForm>
  )
}
