import { ComponentType, IconVariant } from '@flyward/platform'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProtectedRoutes } from '../layout'
import {
  AirframeMaintenanceProgramPage,
  AuxiliaryPowerUnitMaintenanceProgramPage,
  EngineMaintenanceProgramPage,
  FleetPage,
  FlyForwardPage,
  KnowledgeBasePage,
  LandingGearMaintenanceProgramPage,
  NotFoundErrorPage,
  ReportPage,
  ReportsPage,
  StandaloneEnginePage,
  UnauthorizedErrorPage,
  UserManagement,
} from '../pages'
import { AuthDone, AuthFailed } from '../pages/Auth'
import { TestPage } from '../pages/Test'
import { AircraftPage } from '../pages/Fleet/Aircraft/AircraftPage'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { WizardPage } from '@flyward/main-app/pages/KnowledgeBase/Wizard'

export const RouterProvider = () => {
  const routes = [
    { label: 'Fleet Summary', path: `${ROUTES.FLEET.ROOT}`, icon: IconVariant.LocalAirport },
    { label: 'Fly Forward', path: `${ROUTES.FLY_FORWARD}`, icon: IconVariant.FlightTakeOff },
    { label: 'Reports', path: `${ROUTES.REPORTS}`, icon: IconVariant.NewListAlt },
    { label: 'Maintenance Programs', path: `${ROUTES.MAINTENANCE_PROGRAMS.ROOT}`, icon: IconVariant.Settings },
  ]

  return (
    <Routes>
      <Route path={`${ROUTES.ROOT}`} element={<Navigate to={`${ROUTES.FLEET.ROOT}`} replace />} />
      <Route path={``} element={<Navigate to={`${ROUTES.FLEET.ROOT}`} replace />} />
      <Route path={`${ROUTES.AUTH.DONE}`} element={<AuthDone />} />
      <Route path={`${ROUTES.AUTH.FAILED}`} element={<AuthFailed />} />
      <Route path={`${ROUTES.AUTH.UNAUTHORIZED}`} element={<UnauthorizedErrorPage />} />
      <Route element={<ProtectedRoutes routes={routes} />}>
        <Route path={`${ROUTES.FLEET.ROOT}`} element={<FleetPage />} />
        <Route path={`${ROUTES.FLEET.AIRCRAFTS}/:id`} element={<AircraftPage />} />
        <Route path={`${ROUTES.FLEET.ENGINES}/:id`} element={<StandaloneEnginePage />} />
        <Route path={`${ROUTES.FLY_FORWARD}/:assetIds?`} element={<FlyForwardPage />} />
        <Route path={`${ROUTES.REPORTS}`} element={<ReportsPage />} />
        <Route path={`${ROUTES.REPORTS}/:id`} element={<ReportPage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.ROOT}`} element={<Navigate replace to={`${ROUTES.MAINTENANCE_PROGRAMS.EMP}`} />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.EMP}`} element={<KnowledgeBasePage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.AMP}`} element={<KnowledgeBasePage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.APUMP}`} element={<KnowledgeBasePage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.LGMP}`} element={<KnowledgeBasePage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.AMP}/:id`} element={<AirframeMaintenanceProgramPage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.EMP}/:id`} element={<EngineMaintenanceProgramPage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.LGMP}/:id`} element={<LandingGearMaintenanceProgramPage />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.APUMP}/:id`} element={<AuxiliaryPowerUnitMaintenanceProgramPage />} />
        {/* Wizard Routes */}
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.WIZARD.ENGINE}`} element={<WizardPage type={ComponentType.EngineUnit} />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.WIZARD.AIRFRAME}`} element={<WizardPage type={ComponentType.Airframe} />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.WIZARD.LANDING_GEAR}`} element={<WizardPage type={ComponentType.LandingGear} />} />
        <Route path={`${ROUTES.MAINTENANCE_PROGRAMS.WIZARD.AUXILIARY_POWER_UNIT}`} element={<WizardPage type={ComponentType.AuxiliaryPowerUnit} />} />
        <Route path={`${ROUTES.USER_MANAGEMENT}`} element={<UserManagement />} />
        <Route path="/test" element={<TestPage />} />
      </Route>
      <Route path="*" element={<NotFoundErrorPage />} />
    </Routes>
  )
}
