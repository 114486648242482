import { AuthorizedElement, useUserAuthenticated, useZitadelAuthenticated, ElementForSupportUser } from '@flyward/appIdentity'
import { IconVariant, useNavigationState, cn, Button, ButtonVariant } from '@flyward/platform'
import { isNil } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './NavMenu.module.css'
import { TenantSelector } from '../../components/TenantSelector'
import { ROUTES } from '../../../providers/routes'

interface INavMenuProps {
  routes: Array<{ path: string; label: string; icon: IconVariant }>
}

export const NavMenu = ({ routes }: INavMenuProps): React.ReactElement<INavMenuProps> => {
  const { isCollapsed, isNavigationHidden, isHidden, toggleExpansion } = useNavigationState()
  const location = useLocation()
  const { loggedUser, isUserLogged } = useUserAuthenticated()
  const navigate = useNavigate()
  const authContext = useZitadelAuthenticated()

  return (
    <div
      className={cn(
        'transition-all z-50 flex flex-shrink-0 whitespace-nowrap bg-black-0 duration-300 ease-in-out',
        { hidden: isNavigationHidden || isHidden },
        styles.NavMenu,
        isCollapsed ? 'w-19' : 'w-63',
      )}
    >
      <div className="flex w-full flex-col justify-between pb-6 pl-5 pt-2">
        <ul className="m-0 flex flex-col gap-y-4">
          {routes.map((route) => (
            <li key={route.path} className="mx-0 w-full p-0">
              <Button
                variant={ButtonVariant.Navigation}
                leftIcon={route.icon}
                label={isCollapsed ? '' : route.label}
                isPressed={location.pathname.includes(route.path)}
                className="transition-opacity w-full duration-300 ease-in-out"
                onClick={() => {
                  navigate(route.path)
                }}
              />
            </li>
          ))}
        </ul>
        <div className="h-auto w-full">
          <ElementForSupportUser>
            <TenantSelector />
          </ElementForSupportUser>
          <AuthorizedElement>
            <Button
              data-permission-element-id="user-management-btn"
              variant={ButtonVariant.Account}
              leftIcon={IconVariant.UserManagement}
              label={isCollapsed ? '' : 'User Management'}
              className="transition-opacity w-full duration-300 ease-in-out"
              onClick={() => {
                navigate(ROUTES.USER_MANAGEMENT)
              }}
            />
          </AuthorizedElement>
          <Button
            variant={ButtonVariant.Account}
            leftIcon={IconVariant.AccountCircle}
            label={isCollapsed ? '' : (isUserLogged ?? false) && !isNil(loggedUser) ? loggedUser.fullName : ''}
            className="transition-opacity w-full duration-300 ease-in-out"
            onClick={() => {}}
          />
          {(isUserLogged ?? false) && (
            <Button
              variant={ButtonVariant.Account}
              leftIcon={IconVariant.LogOut}
              label={isCollapsed ? '' : 'Log out'}
              className="transition-opacity w-full duration-300 ease-in-out"
              onClick={() => {
                authContext.logout()
              }}
            />
          )}
        </div>
      </div>
      <div className="flex w-6.5 items-center">
        <Button
          data-testid="nav-menu-toggle"
          variant={ButtonVariant.Collapsible}
          leftIcon={isCollapsed ? IconVariant.KeyboardArrowRight : IconVariant.KeyboardArrowLeft}
          onClick={toggleExpansion}
          className={cn('transition-all z-50 duration-300 ease-in-out', isCollapsed ? 'ml-2.5' : 'ml-0')}
        />
      </div>
    </div>
  )
}
