import { useUpdateLlpStackMutation } from '@flyward/assets/store/api'
import { AlignmentStatus, VerificationStatus, VerificationStatusToAlignmentStatus } from '@flyward/platform'
import { Button, ButtonVariant, cn, CnForm } from '@flyward/platform/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { DraggableLlpStackSchema, type DraggableLlp, type DraggableLlpStack, type UpdateLlpStackInput } from '../../../../../../models'
import { type VerifyEngineResultDto } from '../../../../../../models/aircraftComponents/aircraftEngine/verify/'
import { type VerifyLlpProgramDto } from '../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpProgramDto'
import { AssetLlpsTable } from './asset'
import { KbLlpsTable } from './kb'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError } from '@flyward/platform/services'

interface IEngineAlignmentProps {
  assetId: string
  engineResult: VerifyEngineResultDto
  setIsAssetVerificationModalOpen: (isOpen: boolean) => void
}

const EngineAlignment = ({ assetId, engineResult, setIsAssetVerificationModalOpen }: IEngineAlignmentProps) => {
  const [updateLlpStack] = useUpdateLlpStackMutation()

  const values: DraggableLlpStack = {
    llps: engineResult.llpStackResults.map((l) => {
      return {
        llp: l.llp!,
        alignmentStatus: VerificationStatusToAlignmentStatus(l.status),
      }
    }),
    removedItemIds: [],
  }

  const updateLlpsSubmitRef = useRef<HTMLInputElement | null>(null)

  const form = useForm<DraggableLlpStack>({
    defaultValues: values,
    values,
    resolver: zodResolver(DraggableLlpStackSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleComponentUpdate,
    formState,
    getValues: getComponentFormValues,
    setValue: setComponentFormValue,
    control: componentFormControl,
    trigger: triggerComponentValidation,
  } = form

  const assetLlpsFormValues: DraggableLlpStack = getComponentFormValues()
  const removedLlpsIds: string[] = assetLlpsFormValues?.removedItemIds ?? []

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Engine alignment formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerComponentValidation()
  }, [isValid, triggerComponentValidation])

  const onUpdateComponent: SubmitHandler<DraggableLlpStack> = async (llpStack: DraggableLlpStack) => {
    const notNullLlps = llpStack.llps.filter((l) => !isNil(l.llp)).map((l) => l.llp)

    const apiData: UpdateLlpStackInput = {
      engineComponentId: engineResult.engineComponentId,
      llps: notNullLlps,
      addRemoveLlps: {
        addedItems: [], // we don't support adding new LLPS in the alignment screen
        removedItemIds: llpStack.removedItemIds,
      },
    }

    const result = await updateLlpStack({ assetId, data: apiData })
    if (!isNil(result.error)) {
      showError(formatAxiosErrorMessage(result.error?.message))
    }
  }

  const onDeleteExistingLlp = (llpId: string) => {
    const remainingItems: DraggableLlp[] = assetLlpsFormValues.llps.filter((data) => data?.llp?.componentId !== llpId)
    setComponentFormValue('llps', remainingItems, { shouldDirty: true })
    const removedIds = [...removedLlpsIds, llpId]
    setComponentFormValue('removedItemIds', removedIds, { shouldDirty: true })
    triggerComponentValidation()
  }

  const onExistingLlpCopyFromKb = (positionalIndex: number, llp: DraggableLlp) => {
    const kbLlpByPosition: VerifyLlpProgramDto = engineResult.llpStackResults[positionalIndex].llpProgram!

    const llpToSave: DraggableLlp = llp

    llpToSave.llp.componentModel = kbLlpByPosition.model
    llpToSave.alignmentStatus = AlignmentStatus.Success

    setComponentFormValue(`llps.${positionalIndex}`, llpToSave, { shouldDirty: true })
    triggerComponentValidation()
  }

  const persistAssetLllStackInForm = (llpStack: DraggableLlp[]) => {
    setComponentFormValue(`llps`, llpStack, { shouldDirty: true })
    triggerComponentValidation()
  }

  const matchedLlpsCount = engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.Success).length
  const missingInAssetCount = engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.MissingInAsset).length
  const missingInKbCount = engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.MissingInKb).length
  const suggestedAlignmentsCount = engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.SuggestedAlignment).length

  const assetsTotalCount = matchedLlpsCount + missingInKbCount + suggestedAlignmentsCount
  const kbTotalCount = matchedLlpsCount + missingInAssetCount + suggestedAlignmentsCount

  const differentLlpsCount = assetsTotalCount !== kbTotalCount

  return (
    <div className="h-full w-full">
      <div className={cn('flex justify-between')}>
        <p className={cn('text-left')}>
          Engine Model:{' '}
          <strong className="font-semibold">
            {engineResult.engineManufacturer} - {engineResult.engineModel}
          </strong>
        </p>
        <p className={cn('text-right')}>
          Assigned Maintenance Program: <strong className="font-semibold">{engineResult.kbProgramName}</strong>
        </p>
      </div>
      <CnForm {...form}>
        <form onSubmit={handleComponentUpdate(onUpdateComponent)}>
          <div className={cn('flex justify-between', differentLlpsCount && 'text-blue-500')}>
            <p title={`${matchedLlpsCount} matched, ${missingInKbCount} missing in program, ${suggestedAlignmentsCount} suggested alignments`}>
              Total Asset LLPs: {assetsTotalCount}
            </p>
            <p title={`${matchedLlpsCount} matched, ${missingInAssetCount} missing in asset, ${suggestedAlignmentsCount} suggested alignments`}>
              Total Program LLPs: {kbTotalCount}
            </p>
          </div>
          <div className="flex max-h-[calc(100vh-15rem)] flex-row overflow-y-auto">
            <AssetLlpsTable
              componentFormValues={assetLlpsFormValues}
              verifyLLPsResult={engineResult.llpStackResults}
              kbTotalCount={kbTotalCount}
              formControl={componentFormControl}
              onDeleteExistingLlp={onDeleteExistingLlp}
              onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
              persistAssetLllStackInForm={persistAssetLllStackInForm}
            />
            <KbLlpsTable llpResults={engineResult.llpStackResults} />
          </div>

          <input ref={updateLlpsSubmitRef} type="submit" className="hidden" />
        </form>
      </CnForm>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          label="Update"
          disabled={isDirty && !isValid}
          variant={ButtonVariant.ModalConfirm}
          onClick={() => {
            updateLlpsSubmitRef.current?.click()
          }}
        />
        <Button
          label={isDirty ? 'Cancel' : 'Close'}
          variant={ButtonVariant.ModalCancel}
          onClick={() => {
            setIsAssetVerificationModalOpen(false)
          }}
        />
      </div>
    </div>
  )
}

export { EngineAlignment }
