import { EditableInputCellWithLabelWithLabel, AsyncErrorBoundary, InputType, TextCellWithLabel } from '@flyward/platform'
import { type UseFormSetValue, type Control, type UseFormRegister, type UseFormUnregister, type UseFormTrigger } from 'react-hook-form'
import { type EngineProgramDto } from '../../../models'
import { BasicAssumptionsEditable } from '../BasicAssumptionsEditable'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { DerateRatiosEditableTable } from './DerateRatio/DerateRatiosEditableTable'
import { EngineLifeLimitedPartsEditableTable } from './LLPs/EngineLifeLimitedPartsEditableTable'
import { EnginePerformanceRestorationEditableTable } from './EPR/EnginePerformanceRestorationEditableTable'
import { EnvironmentalImpactsEditableTable } from './EnvImpact/EnvironmentalImpactsEditableTab'
import { FlightHoursFlightCyclesRatioEditableTable } from './FHFCRatio/FlightHoursFlightCyclesRatioEditable'
import { StubLifeEditable } from './StubLife/StubLifeEditable'
import { EngineEscalationsEditable } from './EngineEscalations/EngineEscalationsEditable'

interface IEngineMaintenanceProgramEditableProps {
  engineProgram: EngineProgramDto | undefined
  formControl: Control<EngineProgramDto, unknown>
  setFormValue: UseFormSetValue<EngineProgramDto>
  registerFormField: UseFormRegister<EngineProgramDto>
  unRegisterFormField: UseFormUnregister<EngineProgramDto>
  triggerComponentValidation: UseFormTrigger<EngineProgramDto>
}

export const EngineMaintenanceProgramEditable = ({
  engineProgram,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
  triggerComponentValidation,
}: IEngineMaintenanceProgramEditableProps) => {
  return (
    <AsyncErrorBoundary>
      <MaintenanceProgramDetailsArea title="Engine">
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel
            className="basis-1/6"
            label="Program Name"
            formControl={formControl}
            fieldName={`maintenanceProgramName`}
          />
        </div>
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Manufacturer" formControl={formControl} fieldName={`manufacturer`} />
          <EditableInputCellWithLabelWithLabel
            className="basis-1/6"
            label="Engine Model"
            formControl={formControl}
            fieldName={`compatibleComponentModels`}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Year}
            className="basis-1/6"
            label="Base year"
            formControl={formControl}
            fieldName={`kbReferenceYear`}
          />
        </div>
        <EnginePerformanceRestorationEditableTable eprCheck={engineProgram?.eprCheck} formControl={formControl} tableClassName="w-150" />
        <div className="py-2">
          <TextCellWithLabel className="basis-1/6" label="Event type" info={'LLP replacement'} />
        </div>
        <EngineLifeLimitedPartsEditableTable
          existingLlpChecks={engineProgram?.llpChecks}
          newLlpChecks={engineProgram?.llpCheckAddRemoveItems?.addedItems}
          removedLlpChecksId={engineProgram?.llpCheckAddRemoveItems?.removedItemIds}
          engineProgram={engineProgram}
          formControl={formControl}
          setFormValue={setFormValue}
          registerFormField={registerFormField}
          unRegisterFormField={unRegisterFormField}
          tableClassName="w-225"
          triggerComponentValidation={triggerComponentValidation}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Escalation">
        <EngineEscalationsEditable
          formControl={formControl}
          eprEscalationPctFieldName={`eprEscalationPct`}
          llpEscalationPctFieldName={`llpEscalationPct`}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="FH/FC Ratio">
        <FlightHoursFlightCyclesRatioEditableTable
          formControl={formControl}
          existingItems={engineProgram?.flightHoursFlightCyclesRatios}
          newItems={engineProgram?.fhFcRatioAddRemoveItems?.addedItems}
          removedItemIds={engineProgram?.fhFcRatioAddRemoveItems?.removedItemIds}
          setFormValue={setFormValue}
          registerFormField={registerFormField}
          unRegisterFormField={unRegisterFormField}
          tableClassName="w-80"
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Derate Ratio">
        <DerateRatiosEditableTable
          formControl={formControl}
          existingItems={engineProgram?.derateRatios}
          newItems={engineProgram?.derateRatioAddRemoveItems?.addedItems}
          removedItemIds={engineProgram?.derateRatioAddRemoveItems?.removedItemIds}
          setFormValue={setFormValue}
          registerFormField={registerFormField}
          unRegisterFormField={unRegisterFormField}
          tableClassName="w-80"
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Environmental Impact">
        <EnvironmentalImpactsEditableTable
          formControl={formControl}
          existingItems={engineProgram?.environmentalImpacts}
          newItems={engineProgram?.environmentalImpactAddRemoveItems?.addedItems}
          removedItemIds={engineProgram?.environmentalImpactAddRemoveItems?.removedItemIds}
          setFormValue={setFormValue}
          registerFormField={registerFormField}
          unRegisterFormField={unRegisterFormField}
          tableClassName="w-80"
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsEditable
          formControl={formControl}
          downtimeFieldPath={'basicAssumptions.checkDowntimeMonths'}
          delayFieldPath={'basicAssumptions.monthsDelayInPaymentOnClaims'}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Stub Life">
        <StubLifeEditable formControl={formControl} percentageFieldName={`stubLife.intervalStart`} />
      </MaintenanceProgramDetailsArea>
    </AsyncErrorBoundary>
  )
}
