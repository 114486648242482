import { AuthorizedElement } from '@flyward/appIdentity/context/AuthorizedRole/AuthorizedElement'
import { useImportAirframeKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/airframeMaintenancePrograms'
import { useImportAuxiliaryPowerUnitKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/auxiliaryPowerUnitMaintenanceProgram'
import { useImportEngineKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/engineMaintenancePrograms'
import { useImportLandingGearKnowledgeBaseMutation } from '@flyward/knowledgeBase/store/api/landingGearMaintenancePrograms'
import { COMPONENT_TYPES, errorMessages, useSpinnerState } from '@flyward/platform'
import { ButtonWithDialog, CellWithButton, cn, IconVariant, TextCellWithLabel } from '@flyward/platform/components'
import { longDateFormat } from '@flyward/platform/helpers/dateHelpers'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { ComponentType } from '@flyward/platform/models/enums'
import { type ColumnDef, flexRender, getCoreRowModel, type Row, useReactTable } from '@tanstack/react-table'
import { format } from 'date-fns'
import { isNil } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { type NavigateFunction, useNavigate } from 'react-router-dom'
import { type ProgramDto } from '../../models'
import { ChangeState } from './ChangeState/ChangeState'
import { showError, showSuccess } from '@flyward/platform/services'
import { AddModal } from '@flyward/main-app/pages/KnowledgeBase/AddModal'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

type ComponentTypes = ComponentType.Airframe | ComponentType.EngineUnit | ComponentType.LandingGear | ComponentType.AuxiliaryPowerUnit

const labels: Record<ComponentTypes, { singular: string; plural: string }> = {
  [ComponentType.EngineUnit]: { singular: 'Engine', plural: 'Engines' },
  [ComponentType.Airframe]: { singular: 'Airframe', plural: 'Airframes' },
  [ComponentType.AuxiliaryPowerUnit]: { singular: 'APU', plural: "APU's" },
  [ComponentType.LandingGear]: { singular: 'Landing Gear', plural: 'Landing Gears' },
}

const actionCell = (row: Row<ProgramDto>, onDelete: (maintenanceId: string) => Promise<string | undefined>) => {
  return (
    <AuthorizedElement>
      <ButtonWithDialog
        data-permission-element-id="delete-any-maintenance-program"
        isExpanded={row.getIsExpanded()}
        id={row.original.id}
        name={row.original.maintenanceProgramName}
        onConfirm={onDelete}
        type="Maintenance program"
        dataPermissionId="delete-any-maintenance-program"
        actionLabel="Delete"
        icon={IconVariant.Delete}
      />
    </AuthorizedElement>
  )
}

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getFormattedDate = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  const date = new Date(value)
  return format(date, longDateFormat)
}

const generateColumns = (
  componentType: ComponentTypes,
  navigate: NavigateFunction,
  detailsPath: string,
  onDelete: (maintenanceId: string) => Promise<string | undefined>,
  onChangeState: (assetId: string) => Promise<string | undefined>,
): Array<ColumnDef<ProgramDto>> => [
  {
    accessorKey: 'maintenanceProgramName',
    header: () => null,
    cell: ({ getValue, row }) => (
      <CellWithButton
        label={`${labels[componentType].singular} Name`}
        info={getFormattedValue(getValue)}
        onClick={() => {
          navigate(`${detailsPath}/${row.original.id}`)
        }}
      />
    ),
    meta: {
      className: 'basis-12/24',
    },
  },
  {
    accessorKey: 'dateOfCreation',
    header: () => null,
    cell: ({ getValue }) => <TextCellWithLabel label="Date" info={getFormattedDate(getValue)} />,
    meta: {
      className: 'basis-9/24',
    },
  },
  {
    accessorKey: 'isActive',
    header: () => null,
    cell: ({ row, getValue }) => <ChangeState maintenanceId={row.original.id} checked={getValue() as boolean} onChangeState={onChangeState} />,
    meta: {
      className: 'basis-2/24',
    },
  },
  {
    accessorKey: 'delete',
    header: () => null,
    cell: ({ row }) => actionCell(row, onDelete),
    meta: {
      className: 'basis-1/24',
    },
  },
]

interface CustomColumnMeta {
  className?: string
}

interface IMaintenanceProgramsGridProps {
  componentType: ComponentTypes
  programs: ProgramDto[]
  detailsRoute: string
  onDelete: (assetId: string) => Promise<string | undefined>
  onChangeState: (assetId: string) => Promise<string | undefined>
}

const MaintenanceProgramsGrid = ({ programs, componentType, detailsRoute, onDelete, onChangeState }: IMaintenanceProgramsGridProps) => {
  const navigate = useNavigate()

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [airframeImport] = useImportAirframeKnowledgeBaseMutation()
  const [apuImport] = useImportAuxiliaryPowerUnitKnowledgeBaseMutation()
  const [engineImport] = useImportEngineKnowledgeBaseMutation()
  const [landingGearImport] = useImportLandingGearKnowledgeBaseMutation()
  const [isImporting, setIsImporting] = useState(false)
  const { showSpinner, hideSpinner } = useSpinnerState()

  const columns = useMemo(() => generateColumns(componentType, navigate, detailsRoute, onDelete, onChangeState), [componentType, navigate, detailsRoute, onDelete, onChangeState])

  const table = useReactTable<ProgramDto>({
    data: programs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  const handleSave = useCallback(
    async (excelFile?: File) => {
      setIsImporting(true)
      showSpinner()
      switch (componentType) {
        case ComponentType.Airframe: {
          const { error: airframeError } = await airframeImport({ componentType, file: excelFile! })

          if (isNil(airframeError)) {
            showSuccess(<span>Your Airframe maintenance program has been successfully imported!</span>)
          } else {
            showError(formatAxiosErrorMessage(airframeError?.message, errorMessages.kb.import))
          }
          break
        }
        case ComponentType.EngineUnit: {
          const { error: engineError } = await engineImport({ componentType, file: excelFile! })

          if (isNil(engineError)) {
            showSuccess(<span>Your Engine maintenance program has been successfully imported!</span>)
          } else {
            showError(formatAxiosErrorMessage(engineError?.message, errorMessages.kb.import))
          }
          break
        }
        case ComponentType.LandingGear: {
          const { error: landingGearError } = await landingGearImport({ componentType, file: excelFile! })

          if (isNil(landingGearError)) {
            showSuccess(<span>Your Landing Gear maintenance program has been successfully imported!</span>)
          } else {
            showError(formatAxiosErrorMessage(landingGearError?.message, errorMessages.kb.import))
          }
          break
        }
        case ComponentType.AuxiliaryPowerUnit: {
          const { error: apuError } = await apuImport({ componentType, file: excelFile! })

          if (isNil(apuError)) {
            showSuccess(<span>Your APU maintenance program has been successfully imported!</span>)
          } else {
            showError(formatAxiosErrorMessage(apuError?.message, errorMessages.kb.import))
          }
          break
        }
        default:
          break
      }
      hideSpinner()
      setIsImporting(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [airframeImport, apuImport, componentType, engineImport, landingGearImport],
  )

  const handleSuccess = () => {}

  const handleCancel = () => {
    setIsImporting(false)
  }

  return (
    <div className="flex h-full flex-grow flex-col px-4">
      <div className="mb-auto flex flex-grow flex-col">
        <div className="flex justify-between py-4 pr-6">
          <div className="basis-20/24">
            <AuthorizedElement>
              <Button
                data-permission-element-id="knowledge-program-import"
                component="button"
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                data-testid={`add-new-${COMPONENT_TYPES[componentType].id}-kb-btn`}
                onClick={() => {
                  setIsUploadModalOpen(true)
                }}
              >
                {`Add a new ${labels[componentType].singular}`}
              </Button>
            </AuthorizedElement>
            <AddModal
              isOpen={isUploadModalOpen}
              setIsOpen={setIsUploadModalOpen}
              title={`${labels[componentType].singular} Maintenance Program`}
              onConfirm={handleSave}
              onSuccess={handleSuccess}
              onCancel={handleCancel}
              acceptedFileTypes=".xlsx,.xls"
              confirmButtonLabel="Upload"
              isLoading={isImporting}
              wizardUri={COMPONENT_TYPES[componentType].wizardUri}
            />
          </div>
        </div>
        <div className="block h-[calc(100vh-20rem)] w-full overflow-y-auto">
          <table className="w-full">
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id} className={cn('flex border-t px-6 py-2', 'items-center border-primary-light-2')}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className={cn(`flex flex-grow flex-row ${!isNil(cell) ? (cell.column.columnDef.meta as CustomColumnMeta)?.className : ''}`)}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <p className="ml-2 mt-2">{`Total ${labels[componentType].plural}: ${programs.length}`}</p>
    </div>
  )
}

export { MaintenanceProgramsGrid }
