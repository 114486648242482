import { CnForm, ComponentContainer, ComponentTabs, AsyncErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { AirframeDtoSchema, type AirframeDto } from '@flyward/assets/models'
import { AirframeContractualTab } from './AirframeContractualTab'
import { AirframeTechnicalTab } from './AirframeTechnicalTab'
import { useEffect, useRef } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { AirframeLessorContributionsTab } from './AirframeLessorContributionsTab'
import { useUpdateAirframeMutation } from '@flyward/assets/store'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { zodResolver } from '@hookform/resolvers/zod'
import { type AircraftDetailsDto } from '@flyward/assets/models/DTOs/aircraftDetailsDto'
import { isNil } from 'lodash'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError } from '@flyward/platform/services'

interface IAirframeDetailsProps {
  aircraft: AircraftDetailsDto
  airframe: AirframeDto
}

export const AirframeDetails = ({ aircraft, airframe }: IAirframeDetailsProps): React.ReactElement<IAirframeDetailsProps> => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const currentTab = useRef<string>('Technical')

  const [updateAirframe] = useUpdateAirframeMutation()

  const onTabChanged = (activeTab: string) => {
    currentTab.current = activeTab
  }

  const form = useForm<AirframeDto>({
    defaultValues: airframe,
    values: airframe,
    resolver: zodResolver(AirframeDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    register: registerAirframeFormField,
    handleSubmit: handleAirframeUpdate,
    formState,
    getValues: getAirframeFormValues,
    setValue: setAirframeFormValue,
    control: airframeFormControl,
    trigger: triggerAirframeValidation,
    watch: watchFields,
  } = form

  const airframeFormValues: AirframeDto = getAirframeFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Airframe formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerAirframeValidation()
  }, [isValid, triggerAirframeValidation])

  useEffect(() => {
    registerFormRef({ id: [airframeFormValues.assetId, airframeFormValues.componentId], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, airframeFormValues, isDirty, isValid])

  const onUpdateAirframe: SubmitHandler<AirframeDto> = async (data) => {
    const result = await updateAirframe({ assetId: airframe.assetId, airframe: data })
    if (!isNil(result.error)) {
      showError(formatAxiosErrorMessage(result.error?.message))
    }
  }

  const tabs = {
    Technical: {
      content: (
        <AsyncErrorBoundary>
          <AirframeTechnicalTab
            airframe={airframeFormValues}
            formControl={airframeFormControl}
            formState={formState}
            watchFields={watchFields}
            setFormValue={setAirframeFormValue}
          />
        </AsyncErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <AsyncErrorBoundary>
          <AirframeContractualTab
            airframe={airframeFormValues}
            formControl={airframeFormControl}
            formState={formState}
            watchFields={watchFields}
            setFormValue={setAirframeFormValue}
            registerFormField={registerAirframeFormField}
            unRegisterFormField={form.unregister}
          />
        </AsyncErrorBoundary>
      ),
    },
    'Lessor Contribution': {
      content: (
        <AsyncErrorBoundary>
          <AirframeLessorContributionsTab
            airframe={airframeFormValues}
            registerFormField={registerAirframeFormField}
            setFormValue={setAirframeFormValue}
            formControl={airframeFormControl}
            formState={formState}
          />
        </AsyncErrorBoundary>
      ),
    },
  }

  return (
    <ComponentContainer className="p-6">
      <div className="flex w-full flex-col">
        <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Airframe</p>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-6">
            <TextCellWithLabel className="basis-1/6" label="Aircraft Type" info={aircraft.aircraftType} />
            <TextCellWithLabel className="basis-1/6" label="MSN" info={aircraft.serialNumber} />
            <div className="mr-18 basis-4/6" />
          </div>
          <AsyncErrorBoundary>
            <CnForm {...form}>
              <form onSubmit={handleAirframeUpdate(onUpdateAirframe)}>
                <ComponentTabs
                  tabTriggerHeight="h-9"
                  tabTriggerLabelClassName="h-8"
                  tabs={tabs}
                  controlledActiveTab={currentTab.current}
                  onTabChanged={onTabChanged}
                />
                <input type="submit" className="hidden" ref={submitRef} />
              </form>
            </CnForm>
          </AsyncErrorBoundary>
        </div>
      </div>
    </ComponentContainer>
  )
}
