import { type AirframeDto, type ComponentMaintenanceReserveRate } from '@flyward/assets/models'
import { type ContractDeliverySnapshotDto } from '@flyward/assets/models/contract/ContractDeliverySnapshotDto'
import {
  type CheckType,
  CheckTypeDisplay,
  type ComponentUtilizationAtEventDto,
  EditableDateCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  EditableSelectCellWithLabel,
  InputType,
  type ISelectOption,
  MaintenanceReserveCollectionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  SimpleCell,
  SimpleHeader,
  TextCellWithLabel,
  Tooltip,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { type ColumnDef } from '@tanstack/react-table'
import { type UseFormSetValue, type Control, type Path } from 'react-hook-form'

export interface AirframeCheckDisplayDTO {
  checkType: CheckType
  mrRates: ComponentMaintenanceReserveRate | undefined
  contractDeliverySnapshots: ContractDeliverySnapshotDto[]
  componentUtilizationAtEvents: ComponentUtilizationAtEventDto[]
}

export const getReadOnlyColumns: (dynamicMrEolLabel: string, mrActive: boolean, eolActive: boolean) => Array<ColumnDef<AirframeCheckDisplayDTO>> = (
  dynamicMrEolLabel,
  mrActive,
  eolActive,
) => [
  {
    id: 'checkType',
    header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <SimpleCell className="truncate p-0 text-center text-xs text-text-1">{CheckTypeDisplay(row.original.checkType) ?? '-'}</SimpleCell>
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  // {
  //   id: 'lastEvent',
  //   header: () => <SimpleHeader title="Date @ last check" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
  //   cell: ({ row }) => (
  //     <TextCellWithLabel
  //       className="truncate p-0 text-center text-xs text-text-1"
  //       info={formatDate(row.original.componentUtilizationAtEvents?.[0].dateAtLastQualifyingEvent?.toString())}
  //     />
  //   ),
  //   meta: {
  //     className: 'basis-1/8',
  //   },
  // },
  {
    id: 'rateAmount',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} Rate`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <NumberCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        info={row.original.mrRates?.rateAmount}
        preventInfo={!mrActive && !eolActive}
        displayType={NumberDisplayType.Currency}
        currencyDecimals={2}
        label={''}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'utilizationUnit',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} rate type`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <TextCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        info={UtilizationUnitsDisplay(row.original.mrRates?.utilizationUnit)}
        preventInfo={!mrActive && !eolActive}
        label={''}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'ratesYear',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} rate year`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <NumberCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        preventInfo={!mrActive && !eolActive}
        info={row.original.mrRates?.ratesYear}
        displayType={NumberDisplayType.Year}
        label=""
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'maintenanceReserveCollectionType',
    header: () => <SimpleHeader title={`MR collection type`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <TextCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        preventInfo={!mrActive}
        info={MaintenanceReserveCollectionTypeDisplay(row.original.mrRates?.maintenanceReserveCollectionType)}
        label=""
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'currentFund',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} fund`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <NumberCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        label=""
        info={row.original.mrRates?.currentFund}
        isDisabled={!mrActive && !eolActive}
        displayType={NumberDisplayType.CurrencyRounded}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'monthsSinceEventAtContractDelivery',
    header: () => (
      <Tooltip
        content={
          <SimpleHeader
            title={`Months since check at contract delivery`}
            className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0"
          />
        }
        tooltipContent="Months since the last check type at contract delivery"
      />
    ),
    cell: ({ row }) => (
      <NumberCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        label=""
        info={row.original.contractDeliverySnapshots?.[0].monthsSinceEventAtContractDelivery}
        displayType={NumberDisplayType.Integer}
      />
    ),
    meta: {
      className: 'basis-2/8',
    },
  },
]

export const getEditableExistingColumns: (
  formControl: Control<AirframeDto, unknown>,
  setFormValue: UseFormSetValue<AirframeDto>,
  dynamicMrEolLabel: string,
  mrActive: boolean,
  eolActive: boolean,
) => Array<ColumnDef<AirframeCheckDisplayDTO>> = (formControl, setFormValue, dynamicMrEolLabel, mrActive, eolActive) => [
  {
    id: 'checkType',
    header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <SimpleCell className="truncate p-0 text-left text-xs text-text-1">{CheckTypeDisplay(row.original.checkType) ?? '-'}</SimpleCell>
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'lastEvent',
    header: () => <SimpleHeader title="Date @ last check" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <EditableDateCellWithLabelWithLabel
        label={''}
        formControl={formControl}
        fieldName={`assetComponentUtilizationSnapshot.componentUtilizationAtEvents.${row.index}.dateAtLastQualifyingEvent`}
        setFormValue={setFormValue}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'rateAmount',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} Rate`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Currency}
        label={''}
        formControl={formControl}
        fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${row.index}.rateAmount`}
        preventInfo={!mrActive && !eolActive}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'utilizationUnit',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} rate type`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <TextCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        info={UtilizationUnitsDisplay(row.original.mrRates?.utilizationUnit)}
        preventInfo={!mrActive && !eolActive}
        label={''}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'ratesYear',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} rate year`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Year}
        label={''}
        formControl={formControl}
        fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${row.index}.ratesYear`}
        preventInfo={!mrActive && !eolActive}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'maintenanceReserveCollectionType',
    header: () => <SimpleHeader title={`MR collection type`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <TextCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        preventInfo={!mrActive}
        info={MaintenanceReserveCollectionTypeDisplay(row.original.mrRates?.maintenanceReserveCollectionType)}
        label=""
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'currentFund',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} fund`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.IntegerCurrency}
        label={''}
        formControl={formControl}
        fieldName={`airframeContract.airframeMaintenanceReserveContract.airframeMaintenanceRates.${row.index}.currentFund`}
        isReadOnly={!mrActive && !eolActive}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'monthsSinceEventAtContractDelivery',
    header: () => (
      <Tooltip
        content={
          <SimpleHeader
            title={`Months since check at contract delivery`}
            className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0"
          />
        }
        tooltipContent="Months since the last check type at contract delivery"
      />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        label=""
        formControl={formControl}
        fieldName={`airframeContract.contractDeliverySnapshots.${row.index}.monthsSinceEventAtContractDelivery`}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
]

export const getEditableNewColumns: (
  formControl: Control<AirframeDto, unknown>,
  setFormValue: UseFormSetValue<AirframeDto>,
  dynamicMrEolLabel: string,
  mrActive: boolean,
  eolActive: boolean,
  checkTypesOptions: ISelectOption[],
) => Array<ColumnDef<AirframeCheckDisplayDTO>> = (formControl, setFormValue, dynamicMrEolLabel, mrActive, eolActive, checkTypesOptions) => [
  {
    id: 'checkType',
    header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => {
      const fieldName = `addRemoveAirframeChecks.addedItems.${row.index}.checkType` as Path<AirframeDto>

      return (
        <div className="w-full min-w-full text-xs">
          <EditableSelectCellWithLabel
            className="text-center"
            options={checkTypesOptions}
            formControl={formControl}
            fieldName={fieldName}
            onChange={(value) => {
              setFormValue(`addRemoveAirframeChecks.addedItems.${row.index}.previousEvents.0.eventType`, Number(value))
              setFormValue(
                `addRemoveAirframeChecks.addedItems.${row.index}.contract.airframeMaintenanceReserveContract.airframeMaintenanceRates.0.checkType`,
                Number(value),
              )
              setFormValue(`addRemoveAirframeChecks.addedItems.${row.index}.contract.contractDeliverySnapshots.0.checkType`, Number(value))
            }}
          />
        </div>
      )
    },
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'lastEvent',
    header: () => <SimpleHeader title="Date @ last check" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <EditableDateCellWithLabelWithLabel
        label={''}
        formControl={formControl}
        fieldName={`addRemoveAirframeChecks.addedItems.${row.index}.previousEvents.0.dateAtLastQualifyingEvent`}
        setFormValue={setFormValue}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'rateAmount',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} Rate`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Currency}
        label={''}
        formControl={formControl}
        fieldName={`addRemoveAirframeChecks.addedItems.${row.index}.contract.airframeMaintenanceReserveContract.airframeMaintenanceRates.0.rateAmount`}
        preventInfo={!mrActive && !eolActive}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'utilizationUnit',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} rate type`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <TextCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        info={UtilizationUnitsDisplay(row.original.mrRates?.utilizationUnit)}
        preventInfo={!mrActive && !eolActive}
        label={''}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'ratesYear',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} rate year`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Year}
        label={''}
        formControl={formControl}
        fieldName={`addRemoveAirframeChecks.addedItems.${row.index}.contract.airframeMaintenanceReserveContract.airframeMaintenanceRates.0.ratesYear`}
        preventInfo={!mrActive && !eolActive}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'maintenanceReserveCollectionType',
    header: () => <SimpleHeader title={`MR collection type`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <TextCellWithLabel
        className="truncate p-0 text-center text-xs text-text-1"
        preventInfo={!mrActive}
        info={MaintenanceReserveCollectionTypeDisplay(row.original.mrRates?.maintenanceReserveCollectionType)}
        label=""
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'currentFund',
    header: () => (
      <SimpleHeader title={`${dynamicMrEolLabel} fund`} className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.IntegerCurrency}
        label={''}
        formControl={formControl}
        fieldName={`addRemoveAirframeChecks.addedItems.${row.index}.contract.airframeMaintenanceReserveContract.airframeMaintenanceRates.0.currentFund`}
        isReadOnly={!mrActive && !eolActive}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
  {
    id: 'monthsSinceEventAtContractDelivery',
    header: () => (
      <Tooltip
        content={
          <SimpleHeader
            title={`Months since check at contract delivery`}
            className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0"
          />
        }
        tooltipContent="Months since the last check type at contract delivery"
      />
    ),
    cell: ({ row }) => (
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.NaturalNumber}
        label=""
        formControl={formControl}
        fieldName={`addRemoveAirframeChecks.addedItems.${row.index}.contract.contractDeliverySnapshots.0.monthsSinceEventAtContractDelivery`}
      />
    ),
    meta: {
      className: 'basis-1/8',
    },
  },
]
