import {
  Combobox,
  ErrorBoundary,
  errorMessages,
  LoadingFallback,
  ReportDisplayType,
  ReportDisplayTypeOptions,
  useHeaderContent,
  useNavigationState,
} from '@flyward/platform'
import { useGetReportDetailsQuery } from '@flyward/platform/store/slices'
import { isEmpty, isNil, uniqBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { ReportItemAssets } from './ReportItemAssets'
import { IndividualReportItemBody, PortfolioReportBody } from './ReportBody'
import { ReportExcelExtract, ReportItemExcelExtract } from '@flyward/forecasts/components'
import { showError } from '@flyward/platform/services'
import { type IndexedAssetWithReportItem } from '@flyward/forecasts/models'

const ReportPage = () => {
  const { id: reportId } = useParams()
  const { setTitle, setHasBackButton, setCustomElements } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const [reportDisplayType, setReportDisplayType] = useState(ReportDisplayType.AssetReport)

  const onGetReportError = useCallback(() => {
    showError(errorMessages.reports.loadingError)
  }, [])

  const {
    data: report,
    isError: isReportError,
    isLoading: isReportLoading,
    error: reportError,
  } = useGetReportDetailsQuery(
    { reportId: reportId! },
    {
      skip: isNil(reportId) || isEmpty(reportId),
    },
  )

  const [selectedReportItemId, setSelectedReportItemId] = useState<string | null>(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const assetWithReportItemListWithIndexZero: IndexedAssetWithReportItem[] =
    report?.assetWithReportItemList?.map((reportItem) => {
      return {
        ...reportItem,
        index: 0,
      }
    }) ?? []

  const maxIndexOnReportItemArrayForAssetId = (array: IndexedAssetWithReportItem[]) => {
    array.forEach((a, index) => {
      if (index === 0) {
        a.index = 0
      } else if (a.assetId === array[index - 1].assetId) {
        a.index = array[index - 1].index + 1
      } else {
        a.index = 0
      }
    })

    return array
  }

  const IndexedReportItems: IndexedAssetWithReportItem[] = maxIndexOnReportItemArrayForAssetId(assetWithReportItemListWithIndexZero)

  const handleAssetSelection = (reportId: string, index: number) => {
    setSelectedReportItemId(reportId)
    setSelectedIndex(index)
  }

  useEffect(() => {
    if (isReportError) {
      onGetReportError()
    }
  }, [isReportError, onGetReportError])

  useEffect(() => {
    const reportItemIds = () => {
      if (!isNil(report) && !isEmpty(report)) {
        return report?.assetWithReportItemList
          .map((asset) => ({
            reportItemId: asset.reportItemId,
            assetSerialNumber: asset.assetSerialNumber,
          }))
          .filter((item): item is { reportItemId: string; assetSerialNumber: string } => item.reportItemId !== undefined)
      }
      return []
    }
    setHasBackButton(true)
    toggleVisibility()

    const customElements: React.ReactNode[] = []

    if (!isEmpty(reportId) && !isNil(reportId)) {
      if (reportDisplayType === ReportDisplayType.AssetReport) {
        customElements.push(<ReportItemExcelExtract key={`extract-${reportId}`} reportId={reportId} reportItems={reportItemIds()} />)
      } else {
        customElements.push(<ReportExcelExtract key={`extract-portfolio-${reportId}`} reportId={reportId} reportName={report?.reportName ?? ''} />)
      }
    }

    setCustomElements(customElements)

    return () => {
      toggleVisibility()
      setCustomElements([])
    }
  }, [report, reportDisplayType, reportId, setCustomElements, setHasBackButton, toggleVisibility])

  useEffect(() => {
    setTitle(`Report ${report?.reportName ?? ''}`)
    setSelectedReportItemId(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)

    return () => {
      setTitle('')
    }
  }, [setTitle, report])

  if (isNil(report) || !isNil(reportError) || isReportLoading) {
    return (
      <span data-testid="loading-report">
        <LoadingFallback />
      </span>
    )
  }

  const distinctAssets = uniqBy(IndexedReportItems, 'assetSerialNumber')
  const hasOnlyDistinctAssets = distinctAssets.length === IndexedReportItems.length
  const showReportDisplayTypeSwitch = !isEmpty(reportId) && !isNil(reportId) && IndexedReportItems.length > 1 && hasOnlyDistinctAssets

  return (
    <PageContainer>
      <ErrorBoundary>
        <ReportItemAssets
          reportItemAssetBase={IndexedReportItems}
          selectedAsset={selectedReportItemId}
          onAssetSelect={handleAssetSelection}
          isDisabled={reportDisplayType !== ReportDisplayType.AssetReport}
        />

        {showReportDisplayTypeSwitch && (
          <Combobox
            items={ReportDisplayTypeOptions}
            itemLabel="label"
            label="Report Type"
            selectedValues={[reportDisplayType.toString()]}
            setSelectedValues={(values: string[]) => {
              setReportDisplayType(Number(values[0]))
            }}
            className="!w-44"
            hasSearchInput={false}
          />
        )}

        {!isNil(selectedReportItemId) && reportDisplayType === ReportDisplayType.AssetReport && (
          <IndividualReportItemBody
            reportAsset={{
              id: selectedReportItemId,
              index: selectedIndex,
            }}
          />
        )}

        {!isNil(reportId) && reportDisplayType === ReportDisplayType.PortfolioReport && <PortfolioReportBody reportId={reportId} />}
      </ErrorBoundary>
    </PageContainer>
  )
}

export { ReportPage }
