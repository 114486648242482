import { useExportReportQuery } from '@flyward/forecasts/store'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { Button, ButtonVariant, IconVariant } from '@flyward/platform/components'
import { useSpinnerState } from '@flyward/platform/contexts'
import { showError, showSuccess } from '@flyward/platform/services'
import { errorMessages } from '@flyward/platform'

interface IReportExcelExtractProps {
  reportId: string
  reportName: string
}

export const ReportExcelExtract = ({ reportId, reportName }: IReportExcelExtractProps) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const { showSpinner, hideSpinner } = useSpinnerState()

  const { data: file, error } = useExportReportQuery({ reportId }, { skip: !buttonPressed })

  useEffect(() => {
    if (!isNil(file)) {
      if (file.data instanceof Blob) {
        const url = window.URL.createObjectURL(file.data)
        const link = document.createElement('a')
        link.href = url
        link.download = `${!isEmpty(reportName) ? reportName : 'Portfolio Report'}.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } else {
        throw new Error('MediaSource handling is not supported in this method.')
      }

      showSuccess(<span>Portfolio Excel file downloaded</span>)
      setButtonPressed(false)
      hideSpinner()
    }
    if (!isNil(error)) {
      showError(errorMessages.reports.generateExcelError)
      setButtonPressed(false)
      hideSpinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, error])

  const handleDownload = () => {
    setButtonPressed(true)
    showSpinner()
  }

  return (
    <Button
      key={`portfolio-excel-extract-${reportId}`}
      variant={ButtonVariant.Secondary}
      leftIcon={IconVariant.ExcelExtract}
      className="mr-1 h-full px-3"
      onClick={handleDownload}
      disabled={buttonPressed}
    />
  )
}
