import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { type ContractDeliverySnapshotDto } from '@flyward/assets/models/contract/ContractDeliverySnapshotDto'
import {
  BooleanCellWithLabel,
  CheckTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { isEmpty } from 'lodash'
import { useState, useEffect } from 'react'

interface IContractualTabProps {
  buildStandard: number
  currentLlpMrFund: number | null | undefined
  isMrFlag: boolean
  llpMrRate: number | null | undefined
  llpMrRateEscalation: number
  llpMrRateType: UtilizationUnits | null | undefined
  mrRatesYear: number | null | undefined
  returnConditionsLlp: string
  returnConditionsPr: string
  isEolActive: boolean
  contractDeliverySnapshot: ContractDeliverySnapshotDto
}

const ContractualReadOnlyTab = ({
  buildStandard,
  currentLlpMrFund,
  isMrFlag,
  llpMrRate,
  llpMrRateEscalation,
  llpMrRateType,
  mrRatesYear,
  returnConditionsLlp,
  returnConditionsPr,
  isEolActive,
  contractDeliverySnapshot,
}: IContractualTabProps) => {
  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(isEolActive, isMrFlag))
  }, [isEolActive, isMrFlag])

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <NumberCellWithLabel className="basis-1/6" label="Build Standard (FC)" info={buildStandard} displayType={NumberDisplayType.Integer} />
        <BooleanCellWithLabel className="basis-1/6" label="MR Active" info={isMrFlag} />
        <BooleanCellWithLabel className="basis-1/6" label="EOL Applicable" info={isEolActive} />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`${dynamicLabel} rates Year (EPR & LLP)`}
          info={mrRatesYear}
          preventInfo={!isMrFlag && !isEolActive}
          displayType={NumberDisplayType.Year}
        />
        <NumberCellWithLabel
          key={contractDeliverySnapshot.checkType}
          className="basis-1/6"
          label={`Hours since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} at contract delivery`}
          info={contractDeliverySnapshot.hoursSinceEventAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <div className="basis-1/6" />
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label={`LLP ${dynamicLabel} Agreed Escalation`}
          info={llpMrRateEscalation}
          preventInfo={!isMrFlag && !isEolActive}
          displayType={NumberDisplayType.Percentage}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`LLP ${dynamicLabel} rate`}
          info={llpMrRate}
          preventInfo={!isMrFlag && !isEolActive}
          currencyDecimals={2}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`LLP ${dynamicLabel} rate type`}
          info={UtilizationUnitsDisplay(llpMrRateType)}
          preventInfo={!isMrFlag && !isEolActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`Current LLP ${dynamicLabel} fund`}
          info={currentLlpMrFund}
          isDisabled={!isMrFlag && !isEolActive}
          displayType={NumberDisplayType.CurrencyRounded}
        />
        <div className="mr-6 basis-2/6" />
      </div>
      <div className="flex gap-x-6">
        <TextCellWithLabel
          className="basis-1/6 whitespace-pre"
          label="Return Conditions PR"
          info={isEmpty(returnConditionsPr) ? '-' : returnConditionsPr}
        />
        <TextCellWithLabel
          className="basis-1/6 whitespace-pre"
          label="Return Conditions LLP"
          info={isEmpty(returnConditionsLlp) ? '-' : returnConditionsLlp}
        />
        <div className="ml-18 basis-4/6" />
      </div>
      <div className="flex gap-x-6">
        <div className="mr-18 basis-4/6" />
      </div>
    </div>
  )
}

export { ContractualReadOnlyTab }
export type { IContractualTabProps }
