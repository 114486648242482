// Platform imports
import { ComponentType } from '@flyward/platform'

// Engine imports
import { General, PerformanceAndLLP, AdjustmentsAndCostGrowth, ReviewAndPublish } from './WizardScreens/engine'

// Common imports
import { BasicAssumptions } from './WizardScreens/common/BasicAssumptions'

// Airframe imports
import {
  General as AirframeGeneral,
  ChecksEscalationsAssumptions as AirframeChecksEscalationsAssumptions,
  ReviewAndPublish as AirframeReviewAndPublish,
} from './WizardScreens/airframe'

// Landing Gear imports
import { ChecksEscalationsAssumptions as LGChecksEscalationsAssumptions, ReviewAndPublish as LGReviewAndPublish } from './WizardScreens/landingGear'

// APU imports
import { ChecksEscalationsAssumptions as APUChecksEscalationsAssumptions, ReviewAndPublish as APUReviewAndPublish } from './WizardScreens/apu'

const BASE_WIZARD_CONFIG = {
  [ComponentType.EngineUnit]: {
    wizardTitle: 'Engine Setup',
    stepperTitle: 'Setting up new engine program',
    steps: [
      { label: 'General', subLabel: null, component: General, key: 'general' },
      { label: 'Performance & LLP', subLabel: null, component: PerformanceAndLLP, key: 'perfLLP' },
      {
        label: 'Adjustments & Cost Growth',
        subLabel: 'Optional',
        component: AdjustmentsAndCostGrowth,
        key: 'adjustCostGrowth',
      },
      { label: 'Basic Assumptions', subLabel: 'Optional', component: BasicAssumptions, key: 'basicAssumptions' },
      { label: 'Review & Publish', subLabel: null, component: ReviewAndPublish, key: 'reviewPublish' },
    ],
  },
  [ComponentType.Airframe]: {
    wizardTitle: 'Airframe Setup',
    stepperTitle: 'Setting up new airframe program',
    steps: [
      { label: 'General', subLabel: null, component: AirframeGeneral, key: 'general' },
      {
        label: 'Checks - Escalations and Basic Assumptions',
        subLabel: null,
        component: AirframeChecksEscalationsAssumptions,
        key: 'checksEscalationsAssumptions',
      },
      { label: 'Review & Publish', subLabel: null, component: AirframeReviewAndPublish, key: 'reviewPublish' },
    ],
  },
  [ComponentType.LandingGear]: {
    wizardTitle: 'Landing Gear Setup',
    stepperTitle: 'Setting up new landing gear program',
    steps: [
      { label: 'General', subLabel: null, component: General, key: 'general' },
      {
        label: 'Checks - Escalations and Basic Assumptions',
        subLabel: null,
        component: LGChecksEscalationsAssumptions,
        key: 'checksEscalationsAssumptions',
      },
      { label: 'Review & Publish', subLabel: null, component: LGReviewAndPublish, key: 'reviewPublish' },
    ],
  },
  [ComponentType.AuxiliaryPowerUnit]: {
    wizardTitle: 'Auxiliary Power Unit Setup',
    stepperTitle: 'Setting up new APU program',
    steps: [
      { label: 'General', subLabel: null, component: General, key: 'general' },
      {
        label: 'Checks - Escalations and Basic Assumptions',
        subLabel: null,
        component: APUChecksEscalationsAssumptions,
        key: 'checksEscalationsAssumptions',
      },
      { label: 'Review & Publish', subLabel: null, component: APUReviewAndPublish, key: 'reviewPublish' },
    ],
  },
} as const

export const useWizardConfig = (type: keyof typeof BASE_WIZARD_CONFIG) => BASE_WIZARD_CONFIG[type]
