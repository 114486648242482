import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import queryString from 'query-string'
import {
  type IPostReScheduleWithManualEventRequest,
  type IDeleteReportDetailsMutationRequest,
  type IGetReportDetailsQueryRequest,
  type IGetReportDetailsQueryResponse,
  type IPostFlyForwardRequest,
  type IPostFlyForwardResponse,
  type IPostInitMultiAssetReportMutationRequest,
  type IPostInitMultiAssetReportMutationResponse,
  type IPutReportWithNameMutationRequest,
  type IExportReportItemQueryRequest,
  type IExportReportItemQueryResponse,
  type IExportCustomReportDetailsQueryRequest,
  type IExportCustomReportDetailsQueryResponse,
  type IGetReportStatisticsRequest,
  type IGetReportStatisticsResponse,
  type IGetFullReportDetailsQueryRequest,
  type IGetFullReportDetailsQueryResponse,
  type IExportReportQueryRequest,
  type IExportReportQueryResponse,
} from './types'
import { type AxiosError } from 'axios'

const reportsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportDetails: builder.query<IGetReportDetailsQueryResponse, IGetReportDetailsQueryRequest>({
      query: ({ reportId }) => ({
        url: APIRoutes.ForecastModule.ReportsController.GetSingleReport(reportId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'reports', id: request.reportId }],
    }),
    getFullReportDetails: builder.query<IGetFullReportDetailsQueryResponse, IGetFullReportDetailsQueryRequest>({
      query: ({ reportId }) => ({
        url: APIRoutes.ForecastModule.ReportsController.GetReportWithReportItems(reportId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'fullReports', id: request.reportId }],
    }),
    initMultiAssetReport: builder.mutation<IPostInitMultiAssetReportMutationResponse, IPostInitMultiAssetReportMutationRequest>({
      query: ({ assetIds }) => {
        const params = queryString.stringify({ assetIds }, { arrayFormat: 'none' })
        return {
          url: `${APIRoutes.ForecastModule.FlyForwardController.InitMultiAssetsFly()}?${params}`,
          method: 'POST',
        }
      },
    }),
    flyForward: builder.mutation<IPostFlyForwardResponse, IPostFlyForwardRequest>({
      query: ({ reportItemId, assetType, flyForwardOverrideParams }) => {
        const params = `assetType=${assetType}`

        return {
          url: `${APIRoutes.ForecastModule.FlyForwardController.FlyForward(reportItemId)}?${params}`,
          method: 'POST',
          data: flyForwardOverrideParams,
        }
      },
    }),
    reScheduleWithManualEvent: builder.mutation<IPostFlyForwardResponse, IPostReScheduleWithManualEventRequest>({
      query: ({ reportItemId, assetType, manualEvent }) => {
        const params = `assetType=${assetType}`

        return {
          url: `${APIRoutes.ForecastModule.FlyForwardController.ReFlyForwardWithManualEvent(reportItemId)}?${params}`,
          method: 'POST',
          data: manualEvent,
        }
      },
      invalidatesTags: (_, __, _request) => [{ type: 'reportStatistics' }],
    }),
    getReportStatistics: builder.query<IGetReportStatisticsResponse, IGetReportStatisticsRequest>({
      query: ({ id }) => ({
        url: APIRoutes.ForecastModule.ReportsController.GetReportStatistics(id),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'reportStatistics', id: request.id }],
    }),
    saveReportWithName: builder.mutation<string, IPutReportWithNameMutationRequest>({
      query: ({ id, name }) => ({
        url: APIRoutes.ForecastModule.ReportsController.Save(id),
        method: 'PUT',
        data: `"${name}"`,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'reportsSearch' }, { type: 'userNames' }],
    }),
    deleteReport: builder.mutation<void, IDeleteReportDetailsMutationRequest>({
      query: ({ reportId }) => ({
        url: APIRoutes.ForecastModule.ReportsController.Delete(reportId),
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'reportsSearch' }, { type: 'userNames' }],
    }),
    exportReport: builder.query<IExportReportQueryResponse, IExportReportQueryRequest>({
      queryFn: async (
        { reportId },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: IExportReportQueryResponse } | { error: AxiosError<unknown, unknown> }> => {
        let blob: IExportReportQueryResponse

        try {
          const exportReport = await baseQuery({
            url: APIRoutes.ForecastModule.ReportsController.ExportReport(reportId),
            responseType: 'blob',
            method: 'GET',
          })

          if (exportReport.error != null) {
            console.error('query failed to download report excel file', exportReport.error)
            return { error: exportReport.error }
          }

          blob = { data: exportReport.data as Blob }
        } catch (e) {
          console.error('Failed to download report excel file', e)
          return { error: e as AxiosError<unknown, unknown> }
        }

        return { data: blob }
      },
      providesTags: (_, __, request) => ['apiData', { type: 'reports', id: request.reportId }],
    }),
    exportReportItem: builder.query<IExportReportItemQueryResponse[], IExportReportItemQueryRequest>({
      queryFn: async (
        { reportId, reportItemIds },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: IExportReportItemQueryResponse[] } | { error: AxiosError<unknown, unknown> }> => {
        const blobs: IExportReportItemQueryResponse[] = []

        for (const reportItemId of reportItemIds) {
          try {
            const exportReport = await baseQuery({
              url: APIRoutes.ForecastModule.ReportsController.ExportReportItem(reportId, reportItemId),
              responseType: 'blob',
              method: 'GET',
            })

            if (exportReport.error != null) {
              console.error('query failed to download report excel file', exportReport.error)
              return { error: exportReport.error }
            }

            blobs.push({ data: exportReport.data as Blob, reportItemId })
          } catch (e) {
            console.error('Failed to download report excel file', e)
            return { error: e as AxiosError<unknown, unknown> }
          }
        }

        return { data: blobs }
      },
      providesTags: (_, __, request) => ['apiData', { type: 'reports', id: request.reportId }],
    }),
    customExportReportItem: builder.query<IExportCustomReportDetailsQueryResponse[], IExportCustomReportDetailsQueryRequest>({
      queryFn: async (
        { reportId, reportItemIds, settings },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: IExportReportItemQueryResponse[] } | { error: AxiosError<unknown, unknown> }> => {
        const blobs: IExportReportItemQueryResponse[] = []

        for (const reportItemId of reportItemIds) {
          try {
            const exportReport = await baseQuery({
              url: APIRoutes.ForecastModule.ReportsController.CustomExportReportItem(reportId, reportItemId),
              data: settings,
              responseType: 'blob',
              method: 'POST',
            })

            if (exportReport.error != null) {
              console.error('query failed to download report excel file', exportReport.error)
              return { error: exportReport.error }
            }

            blobs.push({ data: exportReport.data as Blob, reportItemId })
          } catch (e) {
            console.error('Failed to download report excel file', e)
            return { error: e as AxiosError<unknown, unknown> }
          }
        }

        return { data: blobs }
      },
      providesTags: (_, __, request) => ['apiData', { type: 'reports', id: request.reportId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetReportDetailsQuery,
  useGetFullReportDetailsQuery,
  useGetReportStatisticsQuery,
  useInitMultiAssetReportMutation,
  useFlyForwardMutation,
  useReScheduleWithManualEventMutation,
  useExportReportQuery,
  useExportReportItemQuery,
  useCustomExportReportItemQuery,
  useSaveReportWithNameMutation,
  useDeleteReportMutation,
  util: reportsApiSliceUtil,
} = reportsApi

type Endpoints = typeof reportsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TReportsApiActions = InitiateActions[keyof InitiateActions]
