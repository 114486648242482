import { TextField } from '@mui/material'
import * as React from 'react'
import { NumericFormat } from 'react-number-format'
import { v4 as uuidv4 } from 'uuid'
import { cn } from '../utils'
import { validateNaturalNumber, validateEmail, validateSafeText, validateNumberFormat } from './inputValidations'

enum InputType {
  Currency = 'currency',
  IntegerCurrency = 'integerCurrency',
  Email = 'email',
  NaturalNumber = 'naturalNumber',
  NaturalPositiveNumber = 'naturalPositiveNumber',
  IntegerPercentage = 'integerPercentage',
  Percentage = 'percentage',
  Text = 'text',
  SafeText = 'safeText',
  Decimal = 'decimal',
  Year = 'year',
}

interface IInputProps {
  inputClassName?: string
  labelClassName?: string
  disabled?: boolean
  label?: string
  id?: string
  placeholder?: string
  setValueAfterValidation?: (value: string) => void
  setRawValueOnChange?: (value: string) => void
  setRawValueOnBlur?: (value: string) => void
  setIsValid?: (isValid: boolean) => void
  type?: InputType
  controlledValue?: string
  showErrorMessage?: boolean
  preventNull?: boolean
  size?: 'small' | 'medium'
}

const numericTypes = new Set([
  InputType.Currency,
  InputType.IntegerCurrency,
  InputType.Percentage,
  InputType.IntegerPercentage,
  InputType.Decimal,
  InputType.NaturalNumber,
  InputType.NaturalPositiveNumber,
  InputType.Year,
])

const numericFormatProps = {
  [InputType.Currency]: { prefix: '$', decimalScale: 2, allowNegative: false },
  [InputType.IntegerCurrency]: { prefix: '$', decimalScale: 0, allowNegative: false },
  [InputType.Percentage]: { suffix: '%', decimalScale: 2, allowNegative: false },
  [InputType.IntegerPercentage]: { suffix: '%', decimalScale: 0, allowNegative: false },
  [InputType.Decimal]: { decimalScale: 2, allowNegative: false },
  [InputType.NaturalNumber]: { decimalScale: 0, allowNegative: true },
  [InputType.NaturalPositiveNumber]: { decimalScale: 0, allowNegative: false },
  [InputType.Year]: { decimalScale: 0, allowNegative: false },
}

const inputTypeMap = {
  [InputType.Email]: 'email',
  [InputType.Text]: 'text',
  [InputType.SafeText]: 'text',
}

const Input = ({
  inputClassName,
  label,
  placeholder,
  controlledValue = '',
  setValueAfterValidation = () => {},
  setRawValueOnBlur = () => {},
  setIsValid = () => {},
  type = InputType.Text,
  id = uuidv4(),
  showErrorMessage = true,
  disabled = false,
  size = 'medium',
  ...props
}: Readonly<IInputProps>) => {
  const [isValid, setValidity] = React.useState(true)
  const [internalValue, setInternalValue] = React.useState<string>(controlledValue ?? '')

  React.useEffect(() => {
    setInternalValue(controlledValue)
  }, [controlledValue])

  const validateInput = (value: string): string => {
    let validationResponse = { isValid: true, value }

    switch (type) {
      case InputType.NaturalNumber:
        validationResponse = validateNaturalNumber(value)
        break
      case InputType.NaturalPositiveNumber:
      case InputType.Year:
        validationResponse = validateNaturalNumber(value, true)
        break
      case InputType.Email:
        validationResponse = validateEmail(value)
        break
      case InputType.SafeText:
        validationResponse = validateSafeText(value)
        break
      case InputType.Decimal:
      case InputType.Percentage:
      case InputType.Currency:
        validationResponse = validateNumberFormat(value)
        break
      case InputType.Text:
        validationResponse = { isValid: true, value }
        break
    }

    setValidity(validationResponse.isValid)
    setIsValid(validationResponse.isValid)
    return validationResponse.value
  }

  const getErrorMessage = () => {
    if (!isValid) {
      const errorMessages: { [key in InputType]?: string } = {
        [InputType.SafeText]: 'Invalid input. Only alphanumeric and ().,-_ characters are allowed.',
        [InputType.Email]: 'Invalid email format.',
        [InputType.NaturalNumber]: 'Only natural numbers allowed.',
        [InputType.NaturalPositiveNumber]: 'Only positive numbers allowed.',
        [InputType.Currency]: 'Invalid currency format.',
        [InputType.Percentage]: 'Invalid percentage format.',
        [InputType.Text]: 'Invalid text input.',
        [InputType.Decimal]: 'Invalid decimal format.',
        [InputType.Year]: 'Invalid year format.',
      }
      return errorMessages[type] ?? ''
    }
    return ''
  }

  const inputProps = {
    id,
    label,
    placeholder,
    disabled,
    fullWidth: true,
    error: !isValid,
    helperText: showErrorMessage && !isValid ? getErrorMessage() : '',
  }

  return (
    <div className={cn('relative flex flex-col justify-center gap-y-0.4', inputClassName)}>
      {numericTypes.has(type) ? (
        <NumericFormat
          {...numericFormatProps[type as keyof typeof numericFormatProps]}
          value={internalValue}
          onValueChange={(values) => {
            const validatedValue = validateInput(values.value)
            setInternalValue(validatedValue)
            setValueAfterValidation(validatedValue)
          }}
          onBlur={(_e) => {
            setRawValueOnBlur(internalValue)
          }}
          customInput={TextField}
          thousandSeparator={type !== InputType.Year}
          decimalSeparator="."
          size={size}
          variant="outlined"
          {...props}
          {...inputProps}
        />
      ) : (
        <TextField
          type={inputTypeMap[type as keyof typeof inputTypeMap] ?? 'text'}
          value={internalValue}
          onChange={(e) => {
            const validatedValue = validateInput(e.target.value)
            setInternalValue(validatedValue)
            setValueAfterValidation(validatedValue)
          }}
          onBlur={(_e) => {
            setRawValueOnBlur(internalValue)
          }}
          size={size}
          variant="outlined"
          {...props}
          {...inputProps}
        />
      )}
    </div>
  )
}

export { Input, InputType, type IInputProps }
