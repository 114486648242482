import { isNil } from 'lodash'
import {
  type ComponentMonthlyStatistics,
  type IYearMonth,
  CheckTypeTableDisplay,
  UtilizationUnits,
  CheckType,
  type MonthlyStatistics,
  type IEventViewDetailDto,
  ComponentType,
  type ReportDisplayType,
} from '@flyward/platform'
import { type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'
import { fromIsoToShortFormatDate } from '@flyward/platform/helpers/dateHelpers'
import { type MasterComponentWithEvents, ComponentsEventsTableContent } from './ComponentsEventsTableContent'
import { ComponentsEventsTableHeader } from './ComponentsEventsTableHeader'

interface IEventsGridProps {
  reportDisplayType: ReportDisplayType
  componentsMonthlyStatistics: ComponentMonthlyStatistics[]
  eventsGrid?: IMaintenanceScheduleDisplay[]
  yearMonth?: IYearMonth
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
}

export const EventsGrid = ({
  reportDisplayType,
  componentsMonthlyStatistics = [],
  yearMonth,
  eventsGrid,
  componentTotalStatisticsAtEvent,
}: IEventsGridProps) => {
  const eventsDetails: IEventViewDetailDto[] = []
  const componentsWithEvents: MasterComponentWithEvents[] = []
  const eventTypeName = (element: MonthlyStatistics, componentModule: string, elementComponent: ComponentMonthlyStatistics) => {
    if (elementComponent.forecastedCheck.checkType === CheckType.EngineLlpReplacement) {
      return `${CheckTypeTableDisplay(elementComponent.forecastedCheck.checkType)} - ${componentModule} - ${elementComponent.forecastedCheck.componentModel}`
    }
    return `${CheckTypeTableDisplay(element.checkTypes[0])}`
  }

  componentsMonthlyStatistics.forEach((element) => {
    element.monthlyStatistics
      .filter((stat) => {
        const isSameDate = stat.yearMonth.year === yearMonth?.year && stat.yearMonth.month === yearMonth?.month
        return isSameDate && (stat.isEvent || element.forecastedCheck.componentType === ComponentType.EngineLlp)
      })
      .forEach((s) => {
        componentTotalStatisticsAtEvent = componentTotalStatisticsAtEvent.filter(
          (c) => c.eventYearMonth.year === s.yearMonth.year && c.eventYearMonth.month === s.yearMonth.month,
        )
        const forecastedMileageHours = s.forecastedMileage?.find((t) => t.utilizationUnit === UtilizationUnits.FlightHours)
        const forecastedMileageAPUHours = s.forecastedMileage?.find((t) => t.utilizationUnit === UtilizationUnits.AuxiliaryPowerUnitHours)
        const forecastedMileageCycles = s.forecastedMileage?.find((t) => t.utilizationUnit === UtilizationUnits.FlightCycles)
        const forecastedMileageHoursSlsv =
          s.allUtilizationsSlsv !== undefined
            ? element.forecastedCheck.componentType === ComponentType.AuxiliaryPowerUnit
              ? s.allUtilizationsSlsv.AuxiliaryPowerUnitHours
              : s.allUtilizationsSlsv.FlightHours
            : undefined
        const forecastedMileageCyclesSlsv =
          s.allUtilizationsSlsv !== undefined
            ? element.forecastedCheck.componentType === ComponentType.AuxiliaryPowerUnit
              ? undefined
              : s.allUtilizationsSlsv.FlightCycles
            : undefined

        const maintenanceReserveBalance = s.maintenanceReserveBalance

        const eventGrid = eventsGrid?.find(
          (e) =>
            e.forecastedCheck.componentId === element.forecastedCheck.componentId &&
            e.forecastedCheck.checkType === element.forecastedCheck.checkType,
        )

        const reason = eventGrid?.reason
        const eventId = eventGrid?.id

        let tsn: number | undefined = forecastedMileageHours?.utilizationAmount
        let csn: number | undefined = forecastedMileageCycles?.utilizationAmount
        let tslpr: number | undefined = forecastedMileageHoursSlsv
        let cslpr: number | undefined = forecastedMileageCyclesSlsv

        switch (element.forecastedCheck.componentType) {
          case ComponentType.AuxiliaryPowerUnit:
            tsn = forecastedMileageAPUHours?.utilizationAmount
            csn = undefined // CSN is not relevant for APU
            break

          case ComponentType.EngineLlp:
            tsn = undefined // TSN is not relevant for LLPs
            csn = forecastedMileageCyclesSlsv // Override CSN for LLPs
            tslpr = undefined // TSLPR is not relevant for LLPs
            cslpr = undefined // CSLPR is not relevant for LLPs
            break

          default:
            // No overrides for other component types
            break
        }

        eventsDetails.push({
          componentId: element.forecastedCheck.componentId,
          masterComponentId: s.masterComponentId,
          assetSerialNumber: eventGrid?.assetSerialNumber ?? '',
          defaultUtilizationUnit: s.defaultUtilizationUnit,
          kbLimitReference: s.kbLimitReference,
          remainingUtilizationUntilLimit: s.remainingUtilizationUntilLimit,
          componentSerialNumber: element.forecastedCheck.componentSerialNumber,
          masterComponentSerialNumber: element.forecastedCheck.masterComponentSerialNumber ?? element.forecastedCheck.componentSerialNumber,
          eventType: eventTypeName(s, element.forecastedCheck.componentModule ?? '', element),
          cashFlowOut: s.maintenanceReserveCashFlowOutAtEvent.value,
          eventCost: s.eventCost,
          eventShortfall: s.eventShortfall,
          lessorContribution: s.lessorContributionAtEvent?.value,
          lineStyle: '',
          tsn,
          csn,
          tslpr,
          cslpr,
          componentModule: element.forecastedCheck.componentModule,
          reason,
          eventId,
          monthsDelay: s.maintenanceReserveCashFlowOutAtEvent.monthsDelay,
          mrBalance: maintenanceReserveBalance,
          isEvent: s.isEvent,
        })
      })
  })

  //   cloneDeep(eventsDetails).sort((a, b) => {
  //     const serialComparison = b.masterComponentSerialNumber.localeCompare(a.masterComponentSerialNumber)
  //     if (serialComparison !== 0) {
  //       return serialComparison
  //     }
  //     return a.eventType.localeCompare(b.eventType)
  //   })

  let eventsOnComponent: IEventViewDetailDto[] = []
  for (let index = 0; index < eventsDetails.length; index++) {
    eventsOnComponent.push(eventsDetails[index])

    if (index !== eventsDetails.length - 1) {
      if (eventsDetails[index].masterComponentSerialNumber !== eventsDetails[index + 1].masterComponentSerialNumber) {
        eventsDetails[index].lineStyle = 'border-b-2 border-black'
        componentsWithEvents.push({ masterComponentId: eventsDetails[index].masterComponentId, eventsOnComponent })
        eventsOnComponent = []
      }
    } else {
      eventsDetails[index].lineStyle = 'border-b-2 border-black'
      componentsWithEvents.push({ masterComponentId: eventsDetails[index].masterComponentId, eventsOnComponent })
    }
  }

  const tdStyle = 'pl-[2px] py-2 truncate'
  const borderAndTextStyle = ' border-x border-b border-grey-50 text-start text-xs'
  const thStyle = tdStyle + ' content-end ' + borderAndTextStyle

  return (
    <>
      <div className="pb-2 pl-18 text-start">{!isNil(yearMonth) && fromIsoToShortFormatDate(`${yearMonth?.year}-${yearMonth?.month}-1`)}</div>
      <div className="block h-[calc(100vh-8rem)] w-full overflow-x-auto">
        <table className="relative h-full min-w-full">
          <ComponentsEventsTableHeader thStyle={thStyle} reportDisplayType={reportDisplayType} />
          <ComponentsEventsTableContent
            reportDisplayType={reportDisplayType}
            componentsWithEvents={componentsWithEvents}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
            tdStyle={tdStyle}
            borderAndTextStyle={borderAndTextStyle}
          />
        </table>
      </div>
    </>
  )
}
