import {
  ActionDialog,
  Button,
  ButtonVariant,
  errorMessages,
  IconVariant,
  Input,
  InputType,
  ReportDisplayType,
  useHeaderContent,
} from '@flyward/platform'
import { useSaveReportWithNameMutation } from '@flyward/platform/store'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import {
  selectReportDisplayType,
  selectSuccessfulReportId,
  selectUnsortedReportAssets,
} from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { ReportExcelExtract, ReportItemCustomExcelExtractBtn, ReportItemExcelExtract } from '@flyward/forecasts'
import { showError, showSuccess } from '@flyward/platform/services'

const FlyForwardButtons = () => {
  const reportDisplayType = useAppSelector(selectReportDisplayType)

  const reportId = useAppSelector(selectSuccessfulReportId)
  const unsortedReportAssets = useAppSelector(selectUnsortedReportAssets)

  const { setCustomElements } = useHeaderContent()

  const [reportName, setReportName] = useState('')
  const [isReportNameValid, setIsReportNameValid] = useState(false)

  const [saveReportWithName] = useSaveReportWithNameMutation()
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const handleSave = async () => {
    if (reportId === undefined) {
      return
    }
    const saveResponse = await saveReportWithName({ id: reportId, name: reportName.trim() })
    if (!isNil(saveResponse.error)) {
      showError(errorMessages.forecasting.reportSaveError)
    } else {
      showSuccess(
        <span>
          Your forecast report <strong>{reportName.trim()}</strong> has been successfully saved!
        </span>,
      )
    }
    setIsSaveModalOpen(false)
  }

  useEffect(() => {
    const reportItemIds = () => {
      return unsortedReportAssets
        .map((asset) => ({
          reportItemId: asset.reportItemId,
          assetSerialNumber: asset.assetSerialNumber,
        }))
        .filter((item): item is { reportItemId: string; assetSerialNumber: string } => item.reportItemId !== undefined)
    }

    const customElements: React.ReactNode[] = []

    if (!isEmpty(reportId) && !isNil(reportId)) {
      customElements.push(
        <Button
          key={`save-btn-${reportId}`}
          variant={ButtonVariant.Primary}
          leftIcon={IconVariant.Save}
          className="mr-1 h-full px-3"
          onClick={() => {
            setIsSaveModalOpen(true)
          }}
        />,
      )

      if (reportDisplayType === ReportDisplayType.AssetReport) {
        customElements.push(
          <ReportItemExcelExtract key={`extract-${reportId}`} reportId={reportId} reportItems={reportItemIds()} />,
          <ReportItemCustomExcelExtractBtn key={`custom-extract-${reportId}`} reportId={reportId} reportItems={unsortedReportAssets} />,
        )
      } else {
        customElements.push(<ReportExcelExtract key={`extract-portfolio-${reportId}`} reportId={reportId} reportName={reportName} />)
      }
    }

    setCustomElements(customElements)
  }, [isReportNameValid, isSaveModalOpen, reportDisplayType, reportId, reportName, saveReportWithName, setCustomElements, unsortedReportAssets])

  return (
    <ActionDialog
      isValid={reportName.trim().length >= 3 && isReportNameValid}
      confirmBtnLabel="Save"
      dialogHeader="Save report"
      key={`save-${reportId}`}
      onConfirm={handleSave}
      onCancel={() => {
        setReportName('')
        setIsSaveModalOpen(false)
      }}
      onSuccess={() => {
        setReportName('')
        setIsSaveModalOpen(false)
      }}
      isOpen={isSaveModalOpen}
      setIsOpen={setIsSaveModalOpen}
      dialogContent={
        <Input
          label="Report name"
          labelClassName="text-semibold text-text-1"
          setValueAfterValidation={(value: string) => {
            setReportName(value.trimStart())
          }}
          type={InputType.SafeText}
          inputClassName="w-full mt-2"
          setIsValid={setIsReportNameValid}
        />
      }
    />
  )
}

export { FlyForwardButtons as FlyForwardSave }
