import { type MaintenanceSchedule } from '@flyward/forecasts/models'
import { type ReportItemStatus } from '@flyward/forecasts/models/enums'
import { type PaginatedResult, type AssetComponentsMonthlyStatistics, PageSizes, ReportDisplayType, type IdWithIndex } from '@flyward/platform/models'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type FleetSearchInput, type AssetSearchDTO } from '@flyward/assets/models'
import { initialFilterData } from '../fleet/types'

interface IFlyForwardAssetInformation {
  assetSerialNumber: string
  eventSchedule: MaintenanceSchedule[]
  flyForwardParameters: FlyForwardParametersDTO
  initialFlyForwardParameters: FlyForwardParametersDTO
  assetComponentsMonthlyStatistics?: AssetComponentsMonthlyStatistics
  reportItemId?: string
  reportItemStatus: ReportItemStatus
}

interface IReportAsset extends IFlyForwardAssetInformation {
  assetId: IdWithIndex
}

interface FlyForwardState {
  forecastingAssetsSearchInput: FleetSearchInput
  filteredAssetsData: PaginatedResult<AssetSearchDTO> | undefined
  reportAssets: IReportAsset[]
  reportDisplayType: ReportDisplayType
  selectedAssetId: IdWithIndex | undefined
  successfulReportId: string | undefined
}

const initialState: FlyForwardState = {
  filteredAssetsData: undefined,
  reportAssets: [],
  reportDisplayType: ReportDisplayType.AssetReport,
  selectedAssetId: undefined,
  successfulReportId: undefined,
  forecastingAssetsSearchInput: {
    searchTerm: '',
    expand: true,
    assetType: undefined,
    filterData: initialFilterData.filterSelection,
    Page: 0,
    PageSize: PageSizes.All,
  },
}

const flyForwardSlice = createSlice({
  name: 'flyForwardParameters',
  initialState,
  reducers: {
    resetFlyForwardSlice: (state) => {
      state.filteredAssetsData = initialState.filteredAssetsData
      state.reportAssets = initialState.reportAssets
      state.selectedAssetId = initialState.selectedAssetId
      state.successfulReportId = initialState.successfulReportId
      state.forecastingAssetsSearchInput = initialState.forecastingAssetsSearchInput
    },
    clearAllReportAssets: (state) => {
      state.reportAssets = []
    },
    setSelectedAssetId: (state, { payload: { assetId } }: PayloadAction<{ assetId: IdWithIndex }>) => {
      state.selectedAssetId = assetId
    },
    setForecastingAssetsSearchInput: (state, { payload: { forecastingAssetsSearchInput } }: PayloadAction<{ forecastingAssetsSearchInput: FleetSearchInput }>) => {
      state.forecastingAssetsSearchInput = forecastingAssetsSearchInput
    },
    initFilteredAssetsData: (state, { payload: { newAssets } }: PayloadAction<{ newAssets: PaginatedResult<AssetSearchDTO> }>) => {
      state.filteredAssetsData = newAssets
    },
    appendFilteredAssetsData: (state, { payload: { newAssets } }: PayloadAction<{ newAssets: PaginatedResult<AssetSearchDTO> }>) => {
      state.filteredAssetsData = {
        ...state.filteredAssetsData,
        items: [...(state.filteredAssetsData?.items ?? []), ...newAssets.items],
        totalCount: (state.filteredAssetsData?.totalCount ?? 0) + newAssets.items.length,
        currentPage: newAssets.currentPage,
        pageSize: newAssets.pageSize,
      }
    },
    setSuccessfulReportId: (state, { payload: { reportId } }: PayloadAction<{ reportId: string | undefined }>) => {
      state.reportDisplayType = ReportDisplayType.AssetReport
      state.successfulReportId = reportId
    },
    removeReportAssetByAssetId: (state, { payload: { assetId } }: PayloadAction<{ assetId: IdWithIndex }>) => {
      state.successfulReportId = ''
      const updatedReportAssets = state.reportAssets.filter((reportAsset) => reportAsset.assetId.id !== assetId.id || reportAsset.assetId.index !== assetId.index)

      // shift indexes when remove from selection
      updatedReportAssets.forEach((r) => {
        if (r.assetId.id === assetId.id && r.assetId.index > assetId.index) {
          r.assetId.index--
        }
      })
      state.reportAssets = updatedReportAssets
      state.selectedAssetId = state.reportAssets?.[0]?.assetId
    },
    resetFlyForwardParameters: (state, { payload: { assetId } }: PayloadAction<{ assetId: IdWithIndex }>) => {
      state.successfulReportId = ''
      const reportAssetIdIndex = state.reportAssets.findIndex((reportAssetId) => reportAssetId.assetId.id === assetId.id && reportAssetId.assetId.index === assetId.index)

      if (reportAssetIdIndex > -1) {
        const asset = state.reportAssets[reportAssetIdIndex]
        asset.flyForwardParameters = asset.initialFlyForwardParameters
      }
    },
    setReportAssetsWithInitialParameters: (state, { payload: { newReportAssets } }: PayloadAction<{ newReportAssets: IReportAsset[] }>) => {
      const uniqueNewReportAssets = newReportAssets.filter(
        (asset) => state.reportAssets.findIndex((t) => t.assetId.id === asset.assetId.id && t.assetId.index === asset.assetId.index) === -1,
      )

      state.successfulReportId = ''
      state.reportAssets = [...state.reportAssets, ...uniqueNewReportAssets]
    },
    setFlyForwardParameters: (
      state,
      {
        payload: { assetId, flyForwardParameters, resetDate = false },
      }: PayloadAction<{ assetId: IdWithIndex; flyForwardParameters: FlyForwardParametersDTO; resetDate?: boolean }>,
    ) => {
      const reportAssetIdIndex = state.reportAssets.findIndex((reportAssetId) => reportAssetId.assetId.id === assetId.id && reportAssetId.assetId.index === assetId.index)

      if (resetDate) {
        state.reportAssets[reportAssetIdIndex].flyForwardParameters = {
          ...flyForwardParameters,
          endDate: state.reportAssets[reportAssetIdIndex].initialFlyForwardParameters.endDate,
        }
        return
      }

      state.reportAssets[reportAssetIdIndex].flyForwardParameters = {
        ...flyForwardParameters,
      }
    },
    setAssetFlyResults: (
      state,
      {
        payload: { assetId, assetComponentsMonthlyStatistics, eventSchedule, reportItemId, reportItemStatus },
      }: PayloadAction<{
        assetId: IdWithIndex
        assetComponentsMonthlyStatistics: AssetComponentsMonthlyStatistics
        eventSchedule: MaintenanceSchedule[]
        reportItemId: string
        reportItemStatus: ReportItemStatus
      }>,
    ) => {
      const reportAssetIdIndex = state.reportAssets.findIndex((reportAssetId) => reportAssetId.assetId.id === assetId.id && reportAssetId.assetId.index === assetId.index)
      state.reportAssets[reportAssetIdIndex] = {
        ...state.reportAssets[reportAssetIdIndex],
        assetComponentsMonthlyStatistics,
        eventSchedule,
        reportItemId,
        reportItemStatus,
      }
    },
    initFlyResultsStatus: (
      state,
      {
        payload: { assetId, reportItemStatus },
      }: PayloadAction<{
        assetId: IdWithIndex
        reportItemStatus: ReportItemStatus
      }>,
    ) => {
      state.successfulReportId = ''
      const reportAssetIdIndex = state.reportAssets.findIndex((reportAssetId) => reportAssetId.assetId.id === assetId.id && reportAssetId.assetId.index === assetId.index)
      state.reportAssets[reportAssetIdIndex] = {
        ...state.reportAssets[reportAssetIdIndex],
        reportItemStatus,
      }
    },
    setReportDisplayType: (state, { payload: { reportDisplayType } }: PayloadAction<{ reportDisplayType: ReportDisplayType }>) => {
      state.reportDisplayType = reportDisplayType
    },
  },
})

export const {
  resetFlyForwardSlice,
  appendFilteredAssetsData,
  clearAllReportAssets,
  initFilteredAssetsData,
  setSelectedAssetId,
  setSuccessfulReportId,
  removeReportAssetByAssetId,
  resetFlyForwardParameters,
  setReportAssetsWithInitialParameters,
  setFlyForwardParameters,
  setAssetFlyResults,
  initFlyResultsStatus,
  setForecastingAssetsSearchInput,
  setReportDisplayType,
} = flyForwardSlice.actions
export const { reducer: flyForwardReducer } = flyForwardSlice

export type { IReportAsset, IFlyForwardAssetInformation }
