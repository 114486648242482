import { type AssetDetailsSnapshot } from '@flyward/forecasts/models/entities/AssetDetailsSnapshot'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { type TableColumn, AssetType, getAssetTypeDisplayName, TextCellWithLabel } from '@flyward/platform'
import { fromIsoToFormatDate } from '@flyward/platform/helpers/dateHelpers'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Tooltip as MuiTooltip } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { Link } from 'react-router-dom'

export const columnsEngine: Array<TableColumn<AssetDetailsSnapshot>> = [
  { isHidden: true, title: '', isIdField: true, accessor: 'assetId' },
  { isHidden: true, title: '', accessor: 'serialNumber' },
  { title: 'Operator', accessor: 'lessee' },
  { title: 'Engine Model', accessor: 'engineModel' },
  {
    title: 'DOM',
    accessor: 'dom',
    valueGetter: (value) => (!isEmpty(value) && !isNil(value) ? fromIsoToFormatDate(value as string) : '-'),
  },
  { title: 'Region', accessor: 'region' },
  { title: 'Portfolio', accessor: 'portfolio' },
  { title: 'Tech Spec Date', accessor: 'dateOfLastTechSpec' },
]

export const columnsAircrafts: Array<TableColumn<AssetDetailsSnapshot>> = [
  columnsEngine[0],
  columnsEngine[1],
  columnsEngine[2],
  { title: 'A/C type', accessor: 'type' },
  { title: 'Engine', accessor: 'engineModel' },
  ...columnsEngine.slice(4),
]

export const chooseColumns = (selectedAsset: AssetDetailsSnapshot) => {
  if (selectedAsset.assetType === AssetType.Aircraft) {
    return columnsAircrafts
  }
  return columnsEngine
}

interface IAssetInformationProps {
  asset: AssetDetailsSnapshot
  showSerialNumber?: boolean
}

const AssetInformation = ({ asset, showSerialNumber = false }: IAssetInformationProps) => {
  return (
    <>
      <div className="flex items-center justify-start gap-1 border-b border-primary-light-2 pb-2">
        <p className={'!text-base font-semibold text-text-1'}>
          {getAssetTypeDisplayName(asset.assetType)} {showSerialNumber && asset.serialNumber} Information
        </p>

        <MuiTooltip title="Open asset details in a new tab" arrow placement="right">
          <Link
            to={asset.assetType === AssetType.Aircraft ? `${ROUTES.FLEET.AIRCRAFTS}/${asset.assetId}` : `${ROUTES.FLEET.ENGINES}/${asset.assetId}`}
            target="_blank"
          >
            <OpenInNewIcon fontSize="small" color="primary" />
          </Link>
        </MuiTooltip>
      </div>
      <div className="grid grid-cols-5 gap-4">
        {chooseColumns(asset).map((column) => {
          if (column.isHidden ?? false) {
            return null
          }

          const value =
            column.valueGetter != null ? (column.valueGetter(asset[column.accessor] as string) as string) : (asset[column.accessor] as string)

          return <TextCellWithLabel key={column.title} label={column.title} info={value} />
        })}
      </div>
    </>
  )
}

export { AssetInformation }
