import {
  EventSchedule,
  formatAssetSchedule,
  ResponsiveForecastingSummaryChart,
  Statistics,
  type IMaintenanceScheduleDisplay,
} from '@flyward/forecasts'
import { type EventsScheduleTableInput } from '@flyward/forecasts/components/schedule/ScheduleTable/models'
import {
  AsyncErrorBoundary,
  CollapsibleContainer,
  ComponentContainer,
  type ComponentMonthlyStatistics,
  LoadingFallback,
  ReportDisplayType,
  YearMonth,
} from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { useGetReportStatisticsQuery, type IReportAsset } from '@flyward/platform/store/slices'
import { selectSuccessfulReportId, selectUnsortedReportAssets } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { cloneDeep, isNil } from 'lodash'

const FlyForwardPortfolioReport = () => {
  const reportId = useAppSelector(selectSuccessfulReportId)

  const reportAssets: IReportAsset[] = useAppSelector(selectUnsortedReportAssets)

  const { data: reportStatistics, isLoading: isLoadingReportStatistics } = useGetReportStatisticsQuery({ id: reportId! }, { skip: isNil(reportId) })

  let uniqueEvents: IMaintenanceScheduleDisplay[] = []
  let allEvents: IMaintenanceScheduleDisplay[] = []
  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  let componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[] = []

  const reportItemsSchedules: EventsScheduleTableInput[] = []

  const sortedReportItems = !isArrayEmptyOrNull(reportAssets)
    ? cloneDeep(reportAssets)?.sort((a, b) => {
        return a.assetSerialNumber.localeCompare(b.assetSerialNumber)
      })
    : []

  sortedReportItems.forEach((reportAsset) => {
    const uniqueSchedules: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
      reportAsset.eventSchedule,
      reportAsset.flyForwardParameters.endDate,
      reportAsset.assetSerialNumber,
      reportAsset.reportItemId,
    )

    const allSchedules: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
      reportAsset.eventSchedule,
      reportAsset.flyForwardParameters.endDate,
      reportAsset.assetSerialNumber,
      reportAsset.reportItemId,
      true,
    )

    uniqueEvents = uniqueEvents.concat(uniqueSchedules)
    allEvents = allEvents.concat(allSchedules)

    reportItemsSchedules.push({
      flyForwardParameters: reportAsset.flyForwardParameters,
      reportItemId: reportAsset.reportItemId ?? '',
      assetIdWithIndex: reportAsset.assetId,
      schedules: uniqueSchedules,
    })

    reportAsset?.assetComponentsMonthlyStatistics?.groupedComponentStatistics.forEach((component) => {
      if (component.subComponents !== undefined && component.subComponents.length > 0) {
        componentsMonthlyStatistics.push(...component.subComponents)
      } else {
        componentsMonthlyStatistics.push(component)
      }
    })

    if (!isNil(reportAsset?.assetComponentsMonthlyStatistics?.componentTotalStatisticsAtEvent)) {
      componentTotalStatisticsAtEvent = componentTotalStatisticsAtEvent.concat(
        reportAsset.assetComponentsMonthlyStatistics.componentTotalStatisticsAtEvent,
      )
    }
  })

  if (isLoadingReportStatistics) {
    return (
      <span className="min-h-screen">
        <LoadingFallback />
      </span>
    )
  }

  const isoStartDate = YearMonth.fromYearMonthOrIYearMonth(reportStatistics![0].yearMonth).toISOString()
  const isoEndDate = YearMonth.fromYearMonthOrIYearMonth(reportStatistics![reportStatistics!.length - 1].yearMonth).toISOString()

  return (
    <>
      {!isNil(reportStatistics) && reportStatistics.length > 0 && (
        <ComponentContainer className="p-6">
          <CollapsibleContainer title="Chart" containerClassName="w-full gap-y-0">
            <div className="h-96 w-full">
              <AsyncErrorBoundary>
                <ResponsiveForecastingSummaryChart assetMonthlyStatistics={reportStatistics} />
              </AsyncErrorBoundary>
            </div>
          </CollapsibleContainer>
        </ComponentContainer>
      )}
      {reportItemsSchedules.length > 0 && (
        <EventSchedule
          reportDisplayType={ReportDisplayType.PortfolioReport}
          reportItemsSchedules={reportItemsSchedules}
          uniqueSchedules={uniqueEvents}
          allSchedules={allEvents}
          componentsMonthlyStatistics={componentsMonthlyStatistics}
          componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
        />
      )}

      {!isArrayEmptyOrNull(reportStatistics) && !isArrayEmptyOrNull(componentsMonthlyStatistics) && (
        <Statistics
          reportDisplayType={ReportDisplayType.PortfolioReport}
          allEvents={allEvents}
          assetMonthlyStatisticsSummary={reportStatistics ?? []}
          allReportComponentsMonthlyStatistics={{
            assetMonthlyStatistics: reportStatistics ?? [],
            groupedComponentStatistics: componentsMonthlyStatistics,
            componentTotalStatisticsAtEvent,
          }}
          key={'portfolioReport'}
          isoStartDate={isoStartDate}
          isoEndDate={isoEndDate}
        />
      )}
    </>
  )
}

export { FlyForwardPortfolioReport }
