import { SimpleCell, cn } from '@flyward/platform/components'
import {
  type CheckType,
  CheckTypeDisplay,
  type EventChangeReason,
  type IYearMonth,
  YearMonth,
  getEventChangeReasonDisplayName,
  sortByCheckTypeAndSerial,
  getComponentTypeDisplay,
} from '@flyward/platform/models'
import { type ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { cloneDeep } from 'lodash'
import { useMemo } from 'react'
import { type IMaintenanceScheduleDisplay } from '../../../models/entities/MaintenanceSchedule'
import { ManualEventWizard } from './ManualEvent'
import { type EventsScheduleTableInput } from './models'

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getMonthYear = (getValue: () => unknown) => {
  const value: IYearMonth = getValue() as IYearMonth
  const yearMonth: YearMonth = new YearMonth(value.year, value.month)
  return yearMonth.toString()
}

const getCheckType = (getValue: () => unknown) => {
  const value = getValue() as CheckType
  return CheckTypeDisplay(value)
}

const getEventReason = (getValue: () => unknown) => {
  const value = getValue() as EventChangeReason
  return getEventChangeReasonDisplayName(value)
}

const generateIndividualAssetColumns = (
  reportItemsSchedules: EventsScheduleTableInput[],
  isEditable: boolean,
  isMultiAssetView: boolean,
  onRowClick: (eventYearMonth: IYearMonth) => void,
): Array<ColumnDef<IMaintenanceScheduleDisplay>> => {
  let columns: Array<ColumnDef<IMaintenanceScheduleDisplay>> = [
    {
      accessorKey: 'yearMonth',
      header: () => 'Month',
      cell: ({ getValue, row }) => (
        <SimpleCell
          className="cursor-pointer"
          onClick={() => {
            onRowClick(row.original.yearMonth as IYearMonth)
          }}
        >
          {getMonthYear(getValue)}
        </SimpleCell>
      ),
    },
  ]

  if (isMultiAssetView) {
    columns.push({
      accessorKey: 'assetSerialNumber',
      header: () => 'SN',
      cell: ({ row }) => (
        <SimpleCell
          className="cursor-pointer"
          onClick={() => {
            onRowClick(row.original.yearMonth as IYearMonth)
          }}
        >
          {row.original.assetSerialNumber}
        </SimpleCell>
      ),
    })
  }

  columns = columns.concat([
    {
      accessorKey: 'forecastedCheck.componentType',
      header: () => 'Component Type',
      cell: ({ row }) => (
        <SimpleCell
          className="cursor-pointer font-semibold"
          onClick={() => {
            onRowClick(row.original.yearMonth as IYearMonth)
          }}
        >
          {getComponentTypeDisplay(row.original.forecastedCheck.masterComponentType ?? row.original.forecastedCheck.componentType)}
        </SimpleCell>
      ),
    },
    {
      accessorKey: 'forecastedCheck.checkType',
      header: () => 'Check Type',
      cell: ({ getValue, row }) => (
        <SimpleCell
          className="cursor-pointer font-semibold"
          onClick={() => {
            onRowClick(row.original.yearMonth as IYearMonth)
          }}
        >
          {getCheckType(getValue)}
        </SimpleCell>
      ),
    },
    {
      accessorKey: 'reason',
      header: () => 'Event Reason',
      cell: ({ getValue, row }) => (
        <SimpleCell
          className="cursor-pointer font-semibold"
          onClick={() => {
            onRowClick(row.original.yearMonth as IYearMonth)
          }}
        >
          {getEventReason(getValue)}
        </SimpleCell>
      ),
    },
    {
      accessorKey: 'componentSerialNumber',
      header: () => 'Component',
      cell: ({ getValue, row }) => (
        <SimpleCell
          className="cursor-pointer font-semibold"
          onClick={() => {
            onRowClick(row.original.yearMonth as IYearMonth)
          }}
        >
          {getFormattedValue(getValue)}
        </SimpleCell>
      ),
    },
  ])

  if (isEditable) {
    columns.push({
      accessorKey: 'custom',
      header: () => '',
      cell: ({ row }) => ManualEventWizard(reportItemsSchedules, isEditable, row),
    })
  }

  return columns
}

interface IEventsScheduleTableProps {
  reportItemsSchedules: EventsScheduleTableInput[]
  isLoading: boolean
  isEditable?: boolean
  onRowClick: (eventYearMonth: IYearMonth) => void
}

export const EventsScheduleTable = ({ reportItemsSchedules, isLoading, isEditable = false, onRowClick }: IEventsScheduleTableProps) => {
  const isMultiAssetView = reportItemsSchedules.length > 1

  const allSchedules = reportItemsSchedules.length === 1 ? reportItemsSchedules[0].schedules! : reportItemsSchedules.map((r) => r.schedules!).flat()

  // Sort schedules by date then by asset serial number then by check type
  const sortedSchedules = useMemo(() => {
    return cloneDeep(allSchedules)
      .slice()
      .sort((a, b) => {
        const yearDiff = (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year
        if (yearDiff !== 0) {
          return yearDiff
        } else {
          const monthsDiff = (a.yearMonth as IYearMonth).month - (b.yearMonth as IYearMonth).month
          if (monthsDiff !== 0) {
            return monthsDiff
          } else {
            const assetSerialDif = a.assetSerialNumber.localeCompare(b.assetSerialNumber)
            if (assetSerialDif !== 0) return assetSerialDif

            return sortByCheckTypeAndSerial(
              { checkType: a.forecastedCheck.checkType, masterComponentSerialNumber: a.forecastedCheck.masterComponentSerialNumber },
              { checkType: b.forecastedCheck.checkType, masterComponentSerialNumber: b.forecastedCheck.masterComponentSerialNumber },
            )
          }
        }
      })
  }, [allSchedules])

  const columns = useMemo(
    () => generateIndividualAssetColumns(reportItemsSchedules, isEditable, isMultiAssetView, onRowClick),
    [reportItemsSchedules, isEditable, isMultiAssetView, onRowClick],
  )

  const table = useReactTable<IMaintenanceScheduleDisplay>({
    data: sortedSchedules,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return reportItemsSchedules?.length === 0 || isLoading ? (
    <></>
  ) : (
    <div className="block w-full overflow-x-auto">
      <table className="m-0 w-full p-0">
        <thead className=" bg-header-table">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="px-4 py-2">
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className="pb-2 pt-6 text-left text-sm">
                    {header.isPlaceholder ? null : <div className="pl-2">{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <tr key={index} className={cn(`gap-x-10 px-4 py-2`, index % 2 === 0 ? 'bg-row-even' : 'bg-row-odd', 'hover:bg-gray-300')}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
