import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IImportAssetFromFile,
  type IGetAllAssetsBySearchQueryRequest,
  type IGetAllAssetsBySearchQueryResponse,
  type IGetAllFiltersResponse,
  type IDeleteAssetDetailsMutationRequest,
  type IVerifyAssetQueryRequest,
  type IVerifyAssetQueryResponse,
} from './types'
import { isNil } from 'lodash'
import queryString from 'query-string'

const assetsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    importAssetFromFile: builder.mutation<void, IImportAssetFromFile>({
      query: ({ assetType, assetImportType, file }) => {
        const formData = new FormData()
        formData.append('inputFile', file)
        return {
          url: `${APIRoutes.AssetsModule.AssetsController.Import()}?assetType=${assetType}&assetImportType=${assetImportType}`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': ' multipart/form-data',
          },
        }
      },
      invalidatesTags: [{ type: 'assets' }],
    }),
    deleteAsset: builder.mutation<void, IDeleteAssetDetailsMutationRequest>({
      query: ({ assetId }) => {
        return {
          url: `${APIRoutes.AssetsModule.AssetsController.Delete(assetId)}`,
          method: 'Delete',
        }
      },
      invalidatesTags: [{ type: 'assets' }],
    }),
    getAllAssetsBySearch: builder.query<IGetAllAssetsBySearchQueryResponse, IGetAllAssetsBySearchQueryRequest>({
      query: ({ searchTerm, expand, assetType, filterData, Page, PageSize }) => {
        return {
          url: APIRoutes.AssetsModule.AssetsController.SearchAssets(),
          data: {
            expand,
            SerialNumber: searchTerm,
            AssetType: !isNil(assetType) ? Number(assetType) : null,
            FilterData: filterData,
            Page,
            PageSize,
          },
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      },
      providesTags: (_, __, _request) => ['apiData', { type: 'assets' }],
    }),
    getFilters: builder.query<IGetAllFiltersResponse, void>({
      query: () => ({
        url: APIRoutes.AssetsModule.AssetsController.GetAllFilters(),
        method: 'GET',
      }),
      providesTags: (_, __, _request) => ['apiData', { type: 'assetsFilters' }],
    }),
    verifyAsset: builder.query<IVerifyAssetQueryResponse, IVerifyAssetQueryRequest>({
      query: ({ assetId, airframeProgramId, engineProgramId }) => {
        const params = queryString.stringify({ airframeProgramId, engineProgramId }, { arrayFormat: 'none' })

        return {
          url: `${APIRoutes.AssetsModule.AssetsController.VerifyAsset(assetId)}?${params}`,
          query: { assetId },
          method: 'GET',
        }
      },
      providesTags: (_, __, request) => ['apiData', { type: 'assetVerification', id: `${request?.assetId}-${request?.airframeProgramId}-${request?.engineProgramId}` }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAssetsBySearchQuery,
  useLazyGetAllAssetsBySearchQuery,
  useGetFiltersQuery,
  useLazyGetFiltersQuery,
  useVerifyAssetQuery,
  useLazyVerifyAssetQuery,
  useImportAssetFromFileMutation,
  useDeleteAssetMutation,
  util: assetsApiSliceUtil,
} = assetsApi

type Endpoints = typeof assetsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAssetsApiActions = InitiateActions[keyof InitiateActions]
