import {
  AsyncErrorBoundary,
  Button,
  ButtonVariant,
  CollapsibleContainer,
  ComponentContainer,
  FullScreenPopover,
  type IYearMonth,
  Size,
  type ComponentMonthlyStatistics,
  type ReportDisplayType,
} from '@flyward/platform'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'

import { useCallback, useState } from 'react'
import { type IMaintenanceScheduleDisplay } from '../../../models/entities/MaintenanceSchedule'
import { EventsGrid, EventViewCalendar } from '../EventView'
import { EventsScheduleTable } from './EventsScheduleTable'
import { type EventsScheduleTableInput } from './models'
import { shortMonths } from '@flyward/platform/helpers/dateHelpers'

interface IEventScheduleProps {
  reportDisplayType: ReportDisplayType
  reportItemsSchedules: EventsScheduleTableInput[]
  uniqueSchedules: IMaintenanceScheduleDisplay[]
  allSchedules: IMaintenanceScheduleDisplay[]
  componentsMonthlyStatistics: ComponentMonthlyStatistics[]
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
  isEditable?: boolean
}

export const EventSchedule = ({
  reportDisplayType,
  reportItemsSchedules,
  uniqueSchedules,
  allSchedules,
  componentsMonthlyStatistics,
  componentTotalStatisticsAtEvent,
  isEditable = true,
}: IEventScheduleProps) => {
  const [isEventViewCalendarPopoverOpen, setIsEventViewCalendarPopoverOpen] = useState<boolean>(false)
  const [isEventViewDetailsPopoverOpen, setIsEventViewDetailsPopoverOpen] = useState<boolean>(false)

  const [selectedEventsGrid, setSelectedEventsGrid] = useState<IMaintenanceScheduleDisplay[]>([])
  const [selectYearMonth, setSelectYearMonth] = useState<IYearMonth>()

  const eventsGrid = useCallback(
    (events: IMaintenanceScheduleDisplay[], year: number, month: string) => {
      return events.filter((el) => {
        const { year: eventYear, month: eventMonth } = el.yearMonth as IYearMonth
        const monthIndex = shortMonths.findIndex((m) => m === month) + 1
        return eventYear === year && eventMonth === monthIndex
      })
    },
    [shortMonths],
  )

  const onRowClick = (eventYearMonth: IYearMonth) => {
    setSelectedEventsGrid(eventsGrid(allSchedules, eventYearMonth.year, shortMonths[eventYearMonth.month - 1]))
    setSelectYearMonth(eventYearMonth)
    setIsEventViewDetailsPopoverOpen(true)
  }

  return (
    <>
      <ComponentContainer className="p-6">
        <CollapsibleContainer
          title="Event Schedule"
          containerClassName="w-full"
          button={
            <Button
              label="Event View"
              variant={ButtonVariant.Secondary}
              size={Size.Medium}
              onClick={() => {
                setIsEventViewCalendarPopoverOpen(true)
              }}
            />
          }
        >
          <AsyncErrorBoundary>
            <EventsScheduleTable reportItemsSchedules={reportItemsSchedules} isLoading={false} isEditable={isEditable} onRowClick={onRowClick} />
          </AsyncErrorBoundary>
        </CollapsibleContainer>
      </ComponentContainer>

      <FullScreenPopover
        content={
          <EventViewCalendar
            reportDisplayType={reportDisplayType}
            uniqueEvents={uniqueSchedules}
            allEvents={allSchedules}
            componentsMonthlyStatistics={componentsMonthlyStatistics}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
          />
        }
        isOpen={isEventViewCalendarPopoverOpen}
        onClose={() => {
          setIsEventViewCalendarPopoverOpen(false)
        }}
        popoverTitle="Event View"
      />

      <FullScreenPopover
        content={
          <EventsGrid
            reportDisplayType={reportDisplayType}
            componentsMonthlyStatistics={componentsMonthlyStatistics}
            yearMonth={selectYearMonth}
            eventsGrid={selectedEventsGrid}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
          />
        }
        isOpen={isEventViewDetailsPopoverOpen}
        onClose={() => {
          setIsEventViewDetailsPopoverOpen(false)
        }}
        popoverTitle="Event Details"
      />
    </>
  )
}
