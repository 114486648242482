import { createTheme } from '@mui/material'

// Helper function to get computed CSS variable value
const getCssVar = (variable: string): string => {
  // Get the value from the CSS variable
  const value = getComputedStyle(document.documentElement).getPropertyValue(variable).trim()
  return value || '#FF1493' // Fallback color if variable is not found - hot pink
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: getCssVar('--primary'),
    },
    secondary: {
      main: getCssVar('--secondary'),
    },
    error: {
      main: getCssVar('--error'),
    },
    text: {
      primary: getCssVar('--text-1'),
      secondary: getCssVar('--text-2'),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'var(--black-0)',
            '&:hover': {
              backgroundColor: 'var(--primary-light-2)',
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: "'liga' 1, 'calt' 1",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      },
      styleOverrides: {
        root: {
          border: `1px solid var(--black-40)`,
          borderRadius: '0.25rem',
          backgroundColor: 'var(--text-4)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: '-.6rem',
        },
        shrink: {
          marginTop: '0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root ': {
            height: '2.25rem',
          },
          '&.MuiInputBase-root  input': {
            padding: '0 .5rem',
            backgroundColor: 'var(--black-0)',
          },
          '&.MuiInputBase-sizeSmall': {
            height: '1.25rem',
            fontSize: '0.8rem',
          },
          '&.MuiInputBase-sizeSmall input': {
            padding: '0 .25rem',
            backgroundColor: 'var(--black-0)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: 'var(--header-table)',
        },
        sizeSmall: {
          padding: '0.5rem 1rem',
        },
      },
    },
  },
})
