import { cn, ErrorBoundary, useNavigationState } from '@flyward/platform'
import { useLayoutEffect, useState } from 'react'

interface IPageContainerPros {
  children?: React.ReactNode
  className?: string
}

/**
 * This wraps pages
 */
export const PageContainer = ({ children, className }: IPageContainerPros): React.ReactElement<IPageContainerPros> => {
  const { isCollapsed, isNavigationHidden, isHidden } = useNavigationState()

  const [maxWidth, setMaxWidth] = useState('w-[calc(100vw-.25rem)]')

  useLayoutEffect(() => {
    if (isNavigationHidden || isHidden) {
      setMaxWidth('w-[calc(100vw-1.75rem)]')
    } else if (isCollapsed) {
      setMaxWidth('w-[calc(100vw-4.8rem)]')
    } else {
      setMaxWidth('w-[calc(100vw-16rem)]')
    }
  }, [isNavigationHidden, isCollapsed, isHidden])

  return (
    <div
      data-testid="page-container"
      className={cn(
        `h-max-[calc(100vh-5.25rem)] h-min-[calc(100vh-5.25rem)] transition-all flex
         h-[calc(100vh-5.25rem)] flex-col gap-y-6 overflow-auto px-5 pb-6 duration-300 ease-in-out`,
        maxWidth,
        className,
      )}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  )
}
