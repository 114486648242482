import { PageContainer } from '@flyward/main-app/layout'
import { GenerateAsyncError } from './GenerateAsyncError'
import { AsyncErrorBoundary, Button, ButtonVariant, ErrorBoundary } from '@flyward/platform'
import { GenerateError } from './GenerateError'
import { showSuccess, showError } from '@flyward/platform/services'
import { TestStatusCodes } from './TestStatusCodes'

export const TestPage = () => {
  alert('TestPage')
  return (
    <>
      <div className="flex gap-1">
        <Button
          variant={ButtonVariant.ModalConfirm}
          label="Show Success Notification"
          onClick={() => {
            showSuccess('Success sample')
          }}
        />
        <Button
          variant={ButtonVariant.ModalConfirm}
          label="Show Error Notification"
          onClick={() => {
            showError(<p>Something bad!!</p>)
          }}
        />
      </div>
      Unconfined errors (outside of the page):
      <div className="flex gap-2 pb-4">
        <GenerateAsyncError />
        <GenerateError />
      </div>
      <hr />
      <PageContainer>
        <TestStatusCodes />

        <h2>Async errors testing</h2>
        <div className="bg-gray-400 p-10">
          Page Level Error:
          <GenerateAsyncError />
          <p className="mt-2">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id ipsum cumque reiciendis quam quae necessitatibus sapiente, saepe tenetur distinctio dolores, similique
            reprehenderit ex adipisci expedita voluptate, corporis veniam ut cum!
          </p>
          <div className="bg-gray-400 p-10">
            Contained error:
            <div className="bg-gray-200 p-10">
              <AsyncErrorBoundary>
                <GenerateAsyncError />
                <div className="bg-gray-100 p-10">
                  Sub level:
                  <AsyncErrorBoundary>
                    <GenerateAsyncError />
                  </AsyncErrorBoundary>
                </div>
              </AsyncErrorBoundary>
            </div>
          </div>
        </div>

        <hr />
        <h2>Simple errors testing</h2>
        <div className="bg-gray-400 p-10">
          Page Level Error:
          <GenerateError />
          <p className="mt-2">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id ipsum cumque reiciendis quam quae necessitatibus sapiente, saepe tenetur distinctio dolores, similique
            reprehenderit ex adipisci expedita voluptate, corporis veniam ut cum!
          </p>
          <div className="bg-gray-400 p-10">
            Contained error:
            <div className="bg-gray-200 p-10">
              <ErrorBoundary>
                <GenerateError />
                <div className="bg-gray-100 p-10">
                  Sub level:
                  <ErrorBoundary>
                    <GenerateError />
                  </ErrorBoundary>
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  )
}
