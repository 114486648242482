import { useState, useEffect } from 'react'
import { IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { NumericFormat } from '../common/NumericFormat'

interface TableRowData {
  id: number
  description: string
  module: string
  limit: string
  clp: string
}

interface LLPTableProps {
  rows: TableRowData[]
  onRowChange: (id: number, field: keyof TableRowData, value: string) => void
  onAddRow: () => void
  onDeleteRow: (id: number) => void
  onValidationChange?: (isValid: boolean) => void
}

export const LLPTable = ({ rows, onRowChange, onAddRow, onDeleteRow, onValidationChange = () => {} }: LLPTableProps) => {
  const [touchedFields, setTouchedFields] = useState<Record<number, Record<string, boolean>>>({})

  const handleBlur = (rowId: number, field: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [rowId]: {
        ...(prev[rowId] || {}),
        [field]: true,
      },
    }))
  }

  const checkDuplicateDescription = (currentId: number, description: string): boolean => {
    if (!description.trim()) return false
    const normalizedDescription = description.trim().toLowerCase()
    return rows.some((row) => row.id !== currentId && row.description.trim().toLowerCase() === normalizedDescription)
  }

  useEffect(() => {
    const hasDuplicates = rows.some((row) => row.description.trim() && checkDuplicateDescription(row.id, row.description))
    onValidationChange(!hasDuplicates)
  }, [rows, onValidationChange])

  const hasError = (rowId: number, field: string, value: string) => {
    const isTouched = touchedFields[rowId]?.[field]
    const isRequired = field === 'description' || field === 'limit' || field === 'clp'

    if (!isTouched) return false

    if (field === 'description') {
      return !value.trim() || checkDuplicateDescription(rowId, value)
    }

    return isRequired && !value.trim()
  }

  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Description *</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Module</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Limit [Flight Cycles] *</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>CLP *</TableCell>
            <TableCell width={50} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={{ '& td': { border: 'none' } }}>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.description}
                  onChange={(e) => onRowChange(row.id, 'description', e.target.value)}
                  onBlur={() => handleBlur(row.id, 'description')}
                  error={!!hasError(row.id, 'description', row.description)}
                />
              </TableCell>
              <TableCell>
                <TextField fullWidth value={row.module} onChange={(e) => onRowChange(row.id, 'module', e.target.value)} />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.limit}
                  onBlur={() => handleBlur(row.id, 'limit')}
                  error={!!hasError(row.id, 'limit', row.limit)}
                  InputProps={{
                    inputComponent: NumericFormat,
                  }}
                  onChange={(e) => onRowChange(row.id, 'limit', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.clp}
                  onBlur={() => handleBlur(row.id, 'clp')}
                  error={!!hasError(row.id, 'clp', row.clp)}
                  InputProps={{
                    inputComponent: NumericFormat,
                    startAdornment: '$',
                  }}
                  onChange={(e) => onRowChange(row.id, 'clp', e.target.value)}
                />
              </TableCell>
              <TableCell width={50}>
                {rows.length > 1 && (
                  <IconButton size="small" onClick={() => onDeleteRow(row.id)} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link
        component="button"
        variant="body2"
        onClick={onAddRow}
        sx={{
          mt: 2,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        + Add another row
      </Link>
    </>
  )
}
