import { type EngineProgramDto } from '../../../models'
import { AsyncErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { BasicAssumptionsReadOnly } from '../BasicAssumptionsReadOnly'
import { FlightHoursFlightCyclesRatioReadOnlyTable } from './FHFCRatio/FlightHoursFlightCyclesRatioReadOnlyTable'
import { DerateRatiosReadOnlyTable } from './DerateRatio/DerateRatiosReadOnlyTable'
import { EnvironmentalImpactsReadOnlyTable } from './EnvImpact/EnvironmentalImpactsReadOnlyTable'
import { StubLifeReadOnly } from './StubLife/StubLifeReadOnly'
import { EnginePerformanceRestorationReadOnlyTable } from './EPR/EnginePerformanceRestorationReadOnlyTable'
import { EngineLifeLimitedPartsReadOnlyTable } from './LLPs/EngineLifeLimitedPartsReadOnlyTable'
import { isArray, isNil } from 'lodash'
import { EngineEscalationsReadOnly } from './EngineEscalations/EngineEscalationsReadOnly'

interface IEngineMaintenanceProgramReadOnlyProps {
  engineProgram: EngineProgramDto | undefined
}

export const EngineMaintenanceProgramReadOnly = ({ engineProgram }: IEngineMaintenanceProgramReadOnlyProps) => {
  return (
    <AsyncErrorBoundary>
      <MaintenanceProgramDetailsArea title="Engine">
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" label="Program Name" info={engineProgram?.maintenanceProgramName} />
        </div>
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={engineProgram?.manufacturer} />
          <TextCellWithLabel
            className="basis-1/6"
            label="Engine Model"
            info={
              !isNil(engineProgram?.compatibleComponentModels) && isArray(engineProgram?.compatibleComponentModels)
                ? engineProgram?.compatibleComponentModels?.join(', ')
                : engineProgram?.compatibleComponentModels
            }
          />
          <TextCellWithLabel className="basis-1/6" label="Base year" info={engineProgram?.kbReferenceYear?.toString()} />
        </div>
        <EnginePerformanceRestorationReadOnlyTable eprCheck={engineProgram?.eprCheck} tableClassName="w-150" />
        <div className="py-2">
          <TextCellWithLabel className="basis-1/6" label="Event type" info={'LLP replacement'} />
        </div>
        <EngineLifeLimitedPartsReadOnlyTable llpChecks={engineProgram?.llpChecks} engineProgram={engineProgram} tableClassName="w-225" />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Escalation">
        <EngineEscalationsReadOnly eprEscalationPct={engineProgram?.eprEscalationPct} llpEscalationPct={engineProgram?.llpEscalationPct} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="FH/FC Ratio">
        <FlightHoursFlightCyclesRatioReadOnlyTable
          flightHoursFlightCyclesRatios={engineProgram?.flightHoursFlightCyclesRatios}
          tableClassName="w-80"
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Derate Ratio">
        <DerateRatiosReadOnlyTable derateRatios={engineProgram?.derateRatios} tableClassName="w-80" />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Environmental Impact">
        <EnvironmentalImpactsReadOnlyTable environmentalImpacts={engineProgram?.environmentalImpacts} tableClassName="w-80" />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsReadOnly basicAssumption={engineProgram?.basicAssumptions} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Stub Life">
        <StubLifeReadOnly stubLife={engineProgram?.stubLife} />
      </MaintenanceProgramDetailsArea>
    </AsyncErrorBoundary>
  )
}
