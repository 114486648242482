import { useUpdateAirframeChecksMutation } from '@flyward/assets/store/api'
import { type VerifyAirframeResultDto } from '../../../../../../models/aircraftComponents/airframe/verify/VerifyAirframeResultDto'
import { Button, ButtonVariant, cn, CnForm } from '@flyward/platform/components'
import {
  DraggableAirframeChecksStackSchema,
  type UpdateAirframeChecksInput,
  type DraggableAirframeChecksStack,
  type DraggableAirframeCheck,
  type VerifyAirframeCheckResultDto,
} from '../../../../../../models/aircraftComponents/airframe'
import { type AirframeCheckType, AlignmentStatus, VerificationStatus, VerificationStatusToAlignmentStatus } from '@flyward/platform/models/enums'
import { useEffect, useRef } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { KbAirframeChecksTable } from './kb'
import { AssetAirframeChecksTable } from './asset'
import { showError } from '@flyward/platform/services'

interface IAirframeAlignmentProps {
  assetId: string
  airframeResult: VerifyAirframeResultDto
  setIsAssetVerificationModalOpen: (isOpen: boolean) => void
}

const AirframeAlignment = ({ assetId, airframeResult, setIsAssetVerificationModalOpen }: IAirframeAlignmentProps) => {
  const [updateAirframeChecks] = useUpdateAirframeChecksMutation()

  const values: DraggableAirframeChecksStack = {
    airframeChecks: airframeResult?.checksResults.map((c) => {
      return {
        airframeCheck: c.check!,
        alignmentStatus: VerificationStatusToAlignmentStatus(c.status),
      }
    }),
    removedItemIds: [],
  }

  const updateAirframeChecksSubmitRef = useRef<HTMLInputElement | null>(null)

  const form = useForm<DraggableAirframeChecksStack>({
    defaultValues: values,
    values,
    resolver: zodResolver(DraggableAirframeChecksStackSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleComponentUpdate,
    formState,
    getValues: getComponentFormValues,
    setValue: setComponentFormValue,
    control: componentFormControl,
    trigger: triggerComponentValidation,
  } = form

  const airframeChecksFormValues: DraggableAirframeChecksStack = getComponentFormValues()
  const removedAirframeChecksIds: string[] = airframeChecksFormValues?.removedItemIds ?? []

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Airframe alignment formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerComponentValidation()
  }, [isValid, triggerComponentValidation])

  const onUpdateComponent: SubmitHandler<DraggableAirframeChecksStack> = async (stack: DraggableAirframeChecksStack) => {
    const notNullChecks = stack.airframeChecks.filter((l) => !isNil(l.airframeCheck)).map((l) => l.airframeCheck)

    const apiData: UpdateAirframeChecksInput = {
      airframeComponentId: airframeResult.airframeComponentId,
      existingChecks: notNullChecks,
      addRemoveChecks: {
        addedItems: [], // we don't support adding new Checks in the alignment screen
        removedItemIds: stack.removedItemIds,
      },
    }

    const result = await updateAirframeChecks({ assetId, data: apiData })
    if (!isNil(result.error)) {
      showError(formatAxiosErrorMessage(result.error?.message))
    }
  }

  const onDeleteExistingCheck = (checkId: string) => {
    const remainingItems: DraggableAirframeCheck[] = airframeChecksFormValues.airframeChecks.filter(
      (data) => data?.airframeCheck?.checkId !== checkId,
    )
    setComponentFormValue('airframeChecks', remainingItems, { shouldDirty: true })
    const removedIds = [...removedAirframeChecksIds, checkId]
    setComponentFormValue('removedItemIds', removedIds, { shouldDirty: true })
    triggerComponentValidation()
  }

  const onExistingCheckCopyFromKb = (positionalIndex: number, check: DraggableAirframeCheck) => {
    const kbCheckByPosition: VerifyAirframeCheckResultDto = airframeResult.checksResults[positionalIndex]

    const checkToSave: DraggableAirframeCheck = check

    checkToSave.airframeCheck.checkType = kbCheckByPosition.kbCheck!.checkType as unknown as AirframeCheckType
    checkToSave.alignmentStatus = AlignmentStatus.Success

    setComponentFormValue(`airframeChecks.${positionalIndex}`, checkToSave, { shouldDirty: true })
    triggerComponentValidation()
  }

  const persistAssetCheckStackInForm = (airframeCheckStack: DraggableAirframeCheck[]) => {
    setComponentFormValue(`airframeChecks`, airframeCheckStack, { shouldDirty: true })
    triggerComponentValidation()
  }

  const matchedLlpsCount = airframeResult.checksResults.filter((c) => c.status === VerificationStatus.Success).length
  const missingInAssetCount = airframeResult.checksResults.filter((c) => c.status === VerificationStatus.MissingInAsset).length
  const missingInKbCount = airframeResult.checksResults.filter((c) => c.status === VerificationStatus.MissingInKb).length
  const suggestedAlignmentsCount = airframeResult.checksResults.filter((c) => c.status === VerificationStatus.SuggestedAlignment).length

  const assetsTotalCount = matchedLlpsCount + missingInKbCount + suggestedAlignmentsCount
  const kbTotalCount = matchedLlpsCount + missingInAssetCount + suggestedAlignmentsCount

  const differentLlpsCount = assetsTotalCount !== kbTotalCount

  return (
    <div className="h-full w-full">
      <div className={cn('flex justify-between')}>
        <p className={cn('text-left')}>
          Airframe Model:{' '}
          <strong className="font-semibold">
            {airframeResult.airframeManufacturer} - {airframeResult.airframeModel}
          </strong>
        </p>
        <p className={cn('text-right')}>
          Assigned Maintenance Program: <strong className="font-semibold">{airframeResult.kbProgramName}</strong>
        </p>
      </div>
      <CnForm {...form}>
        <form onSubmit={handleComponentUpdate(onUpdateComponent)}>
          <div className={cn('flex justify-between', differentLlpsCount && 'text-blue-500')}>
            <p title={`${matchedLlpsCount} matched, ${missingInKbCount} missing in program, ${suggestedAlignmentsCount} suggested alignments`}>
              Total Airframe Checks: {assetsTotalCount}
            </p>
            <p title={`${matchedLlpsCount} matched, ${missingInAssetCount} missing in asset, ${suggestedAlignmentsCount} suggested alignments`}>
              Total Airframe Checks: {kbTotalCount}
            </p>
          </div>
          <div className="flex max-h-[calc(100vh-15rem)] flex-row overflow-y-auto">
            <AssetAirframeChecksTable
              componentFormValues={airframeChecksFormValues}
              setComponentFormValues={setComponentFormValue}
              verifyAirframeChecksResult={airframeResult.checksResults}
              kbTotalCount={kbTotalCount}
              formControl={componentFormControl}
              onDeleteExistingAirframeCheck={onDeleteExistingCheck}
              onExistingAirframeCheckCopyFromKb={onExistingCheckCopyFromKb}
              persistAssetAirframeChecksStackInForm={persistAssetCheckStackInForm}
            />
            <KbAirframeChecksTable checksResults={airframeResult.checksResults} />
          </div>

          <input ref={updateAirframeChecksSubmitRef} type="submit" className="hidden" />
        </form>
      </CnForm>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          label="Update"
          disabled={isDirty && !isValid}
          variant={ButtonVariant.ModalConfirm}
          onClick={() => {
            updateAirframeChecksSubmitRef.current?.click()
          }}
        />
        <Button
          label={isDirty ? 'Cancel' : 'Close'}
          variant={ButtonVariant.ModalCancel}
          onClick={() => {
            setIsAssetVerificationModalOpen(false)
          }}
        />
      </div>
    </div>
  )
}

export { AirframeAlignment }
