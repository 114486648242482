import { combineReducers } from '@reduxjs/toolkit'
import { authReducer, flywardApi, flyForwardReducer, fleetReducer } from './slices'
import wizardReducer from '@flyward/main-app/pages/KnowledgeBase/Wizard/store/wizardSlice'

const rootReducer = combineReducers({
  [flywardApi.reducerPath]: flywardApi.reducer,
  auth: authReducer,
  flyForward: flyForwardReducer,
  fleet: fleetReducer,
  wizard: wizardReducer,
})

export { rootReducer }
export type TRootState = ReturnType<typeof rootReducer>
