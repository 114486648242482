import {
  ActionDialog,
  Combobox,
  errorMessages,
  type GetAllUsersDto,
  Input,
  InputType,
  type ISelectOption,
  Role,
  RoleDisplay,
  useHeaderContent,
  UserState,
} from '@flyward/platform'
import { validateEmail, validateSafeText } from '@flyward/platform/components/Input/inputValidations'
import { showSuccess, showError } from '@flyward/platform/services'
import { type IAddEditUserQueryRequest, useAddUserMutation, useEditUserMutation } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

interface IAddEditUserProps {
  user: GetAllUsersDto | null
  isEditMode: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const newUser: GetAllUsersDto = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  role: Role.None,
  state: UserState.None,
  createdAt: new Date(),
  isEmailVerified: false,
}

const roleStates: ISelectOption[] = [
  { label: RoleDisplay(Role.NormalUser), value: Role.NormalUser.toString() },
  { label: RoleDisplay(Role.SuperUser), value: Role.SuperUser.toString() },
  { label: RoleDisplay(Role.Admin), value: Role.Admin.toString() },
]

export const AddEditUser = ({ isOpen, isEditMode, user, setIsOpen }: IAddEditUserProps) => {
  const { setCustomElements } = useHeaderContent()
  const [userForm, setUserForm] = useState<GetAllUsersDto>(user ?? newUser)
  const [isValidFirstName, setIsValidFirstName] = useState<boolean>(false)
  const [isValidLastName, setIsValidLastName] = useState<boolean>(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false)
  const [addUser] = useAddUserMutation()
  const [editUser] = useEditUserMutation()

  useEffect(() => {
    if (!isNil(user)) {
      setUserForm(user)
    } else {
      setUserForm(newUser)
    }
  }, [user, isOpen, isEditMode])

  useEffect(() => {
    const isValidFirstName = validateSafeText(userForm?.firstName ?? '').isValid
    const isValidLastName = validateSafeText(userForm?.lastName ?? '').isValid
    const isValidEmail = validateEmail(userForm?.email ?? '').isValid
    setIsValidFirstName(isValidFirstName)
    setIsValidLastName(isValidLastName)
    setIsValidEmail(isValidEmail)
  }, [userForm?.email, userForm?.firstName, userForm?.lastName])

  const handleSave = useCallback(async () => {
    const user: IAddEditUserQueryRequest = {
      userId: userForm?.userId ?? '',
      firstName: userForm?.firstName ?? '',
      lastName: userForm?.lastName ?? '',
      email: userForm?.email ?? '',
      role: Number(userForm?.role),
    }
    if (isEditMode) {
      const { error: editError } = await editUser(user)
      if (isNil(editError)) {
        showSuccess('Your user has been successfully saved!')
      } else {
        showError(errorMessages.userManagement.saveError)
      }
    } else {
      const { error: addError } = await addUser(user)
      if (isNil(addError)) {
        showSuccess('Your user has been successfully saved!')
      } else {
        showError(errorMessages.userManagement.saveError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForm, isEditMode, editUser, addUser])

  useEffect(() => {
    const updateRole = (value: string) => {
      const role: Role = Number(value)

      setUserForm((prev) => {
        return {
          ...prev,
          role,
        }
      })
    }

    const resetFields = () => {
      setUserForm(newUser)
    }

    const handleSuccess = () => {
      resetFields()
    }

    setCustomElements([
      <ActionDialog
        isValid={isValidFirstName && isValidLastName && isValidEmail && userForm.role !== Role.None}
        confirmBtnLabel="Save"
        dialogHeader={isEditMode ? `Edit user ${user?.firstName}` : 'Add user'}
        key={`save-${!isNil(user) ? user.userId : 'add'}`}
        onConfirm={handleSave}
        onCancel={resetFields}
        onSuccess={handleSuccess}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogContent={
          <>
            <Input
              label="First Name"
              labelClassName="text-semibold text-text-1"
              setValueAfterValidation={(value: string) => {
                setUserForm((prev) => {
                  return {
                    ...prev,
                    firstName: value,
                  }
                })
              }}
              type={InputType.SafeText}
              inputClassName="w-full mb-2 mt-2"
              controlledValue={userForm.firstName}
              setIsValid={setIsValidFirstName}
            />
            <Input
              label="Last Name"
              labelClassName="text-semibold text-text-1"
              setValueAfterValidation={(value: string) => {
                setUserForm((prev) => {
                  return {
                    ...prev,
                    lastName: value,
                  }
                })
              }}
              type={InputType.SafeText}
              inputClassName="w-full mb-2"
              controlledValue={userForm.lastName}
              setIsValid={setIsValidLastName}
            />
            <Input
              label="Email"
              labelClassName="text-semibold text-text-1"
              setValueAfterValidation={(value: string) => {
                setUserForm((prev) => {
                  return {
                    ...prev,
                    email: value,
                  }
                })
              }}
              type={InputType.Email}
              inputClassName="w-full mb-2"
              controlledValue={userForm.email}
              setIsValid={setIsValidEmail}
            />
            <Combobox
              items={roleStates}
              id="userState"
              className="w-full"
              label="Role"
              itemLabel="Role"
              selectedValues={[Number(userForm.role).toString()]}
              setSelectedValues={(value: string[]) => {
                updateRole(value[0])
              }}
            />
          </>
        }
      />,
    ])
  }, [
    setCustomElements,
    addUser,
    handleSave,
    isEditMode,
    isOpen,
    setIsOpen,
    user,
    isValidFirstName,
    isValidLastName,
    isValidEmail,
    userForm.firstName,
    userForm.lastName,
    userForm.email,
    userForm.role,
  ])

  return null
}
