/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import {
  AssetType,
  Button,
  ButtonVariant,
  CheckType,
  Combobox,
  ComponentType,
  DatePicker,
  type EnvironmentalImpactType,
  EnvironmentalImpactTypeOptions,
  FlyToType,
  IconVariant,
  type IdWithIndex,
  ErrorBoundary,
  Size,
  cn,
  getFlyToTypeDisplayName,
  Input,
  InputType,
} from '@flyward/platform'
import { resetFlyForwardParameters, setFlyForwardParameters, setSuccessfulReportId } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import {
  selectReportAssetFlyForwardInitialParametersByAssetId,
  selectReportAssetFlyForwardParametersByAssetId,
} from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { formatDate } from 'date-fns'
import { isNil, isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { EditableRatesParameters } from './Rates/EditableRatesParameters'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { isValidEndDate } from '@flyward/forecasts/helpers'
import {
  getAssetComponentMrRate,
  handleAircraftComponentWaiveRedeliveryConditionsToggle,
  handleEngineHalfLifeLlpReplacementToggle,
  handleEngineLimitsChange,
  handleEngineLimitsEditMode,
  handleEngineWaiveRedeliveryConditionsToggle,
  handleInputChange,
  handleOpEnvironmentChange,
  handleRateChange,
} from './FlyForwardParametersHelpers'
import { EditableEngineLimits } from './Limits'
import { type CheckLimitType } from '@flyward/knowledgeBase/models'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'

export interface IFlyForwardParametersProps {
  assetId: IdWithIndex
  assetType: AssetType
}

export const FlyForwardParameters = ({ assetId, assetType }: IFlyForwardParametersProps) => {
  const [showAdditionalParams, setShowAdditionalParams] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const flyForwardParameters = useAppSelector((state) => selectReportAssetFlyForwardParametersByAssetId(state, assetId))
  const initialFlyForwardParameters = useAppSelector((state) => selectReportAssetFlyForwardInitialParametersByAssetId(state, assetId))

  const opEnvironment: EnvironmentalImpactType | undefined = flyForwardParameters?.commonAssetDetails?.assetDetailsSnapshot?.operatingEnvironment

  const onInputChange = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, field: keyof FlyForwardParametersDTO, value: string | number | boolean): void => {
      handleInputChange(
        flyForwardParameters,
        field,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
              resetDate: params.resetDate,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onRateChange = useCallback(
    (
      flyForwardParameters: FlyForwardParametersDTO,
      masterComponentSerialNumber: string,
      rateType: 'mrRates' | 'eolRates',
      checkType: CheckType,
      field: keyof CheckTyeRateDTO,
      value: number,
    ): void => {
      handleRateChange(
        flyForwardParameters,
        masterComponentSerialNumber,
        rateType,
        checkType,
        field,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onEngineLimitsChange = useCallback(
    (
      flyForwardParameters: FlyForwardParametersDTO,
      engineUnitId: string,
      kbProgramId: string,
      eprCheckLimitType: CheckLimitType,
      value: number,
    ): void => {
      handleEngineLimitsChange(
        flyForwardParameters,
        engineUnitId,
        kbProgramId,
        eprCheckLimitType,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onToggleEngineLimitsEditMode = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, engineUnitId: string, value: boolean): void => {
      handleEngineLimitsEditMode(
        flyForwardParameters,
        engineUnitId,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onEngineHalfLifeLlpReplacementToggle = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, engineUnitId: string, value: boolean): void => {
      handleEngineHalfLifeLlpReplacementToggle(
        flyForwardParameters,
        engineUnitId,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onHandleOpEnvironmentChange = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, value: EnvironmentalImpactType): void => {
      handleOpEnvironmentChange(
        flyForwardParameters,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onEngineWaiveRedeliveryConditionsToggle = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, engineUnitId: string, conditionType: 'epr' | 'llps', value: boolean): void => {
      handleEngineWaiveRedeliveryConditionsToggle(
        flyForwardParameters,
        engineUnitId,
        conditionType,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  const onAircraftComponentWaiveRedeliveryConditionsToggle = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, componentId: string, value: boolean): void => {
      handleAircraftComponentWaiveRedeliveryConditionsToggle(
        flyForwardParameters,
        componentId,
        value,
        () => dispatch(setSuccessfulReportId({ reportId: '' })),
        (params) => {
          dispatch(
            setFlyForwardParameters({
              assetId,
              flyForwardParameters: params.newFlyForwardParameters,
            }),
          )
        },
      )
    },
    [assetId, dispatch],
  )

  useEffect(() => {
    if (!isNil(flyForwardParameters)) {
      const engineRates = flyForwardParameters.masterComponentsRates.filter((e) => e.componentType === ComponentType.EngineUnit)

      const updatedMasterComponentsRates = engineRates.map((e) => {
        const rate = e.mrRates.find((r) => r.checkType === CheckType.Epr)
        if (!isNil(rate) && !isNil(flyForwardParameters.hoursToCyclesRatio)) {
          if (!isArrayEmptyOrNull(e.hoursToCyclesRatioMrSettings)) {
            const newRateAmount = parseFloat(getAssetComponentMrRate(e.hoursToCyclesRatioMrSettings, flyForwardParameters.hoursToCyclesRatio))

            if (rate.rateAmount !== newRateAmount) {
              return {
                ...e,
                mrRates: e.mrRates.map((r) => (r.checkType === CheckType.Epr ? { ...r, rateAmount: newRateAmount } : r)),
              }
            }
          }
        }
        return e
      })

      const newFlyForwardParameters = {
        ...flyForwardParameters,
        masterComponentsRates: [
          ...flyForwardParameters.masterComponentsRates.filter((comp) => comp.componentType !== ComponentType.EngineUnit),
          ...updatedMasterComponentsRates,
        ],
      }

      dispatch(
        setFlyForwardParameters({
          assetId,
          flyForwardParameters: newFlyForwardParameters,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyForwardParameters?.hoursToCyclesRatio])

  const handleRateActive = useCallback(
    (
      originalFlyForwardParameters: FlyForwardParametersDTO,
      masterComponentSerialNumber: string,
      activeType: 'eolIsActive' | 'mrIsActive',
      value: boolean,
    ): void => {
      const newValue = value

      const componentRateToChange = originalFlyForwardParameters.masterComponentsRates.find(
        (rate) => rate.masterComponentSerialNumber === masterComponentSerialNumber,
      )

      if (isNil(componentRateToChange)) {
        return
      }

      const unchangedComponentRates = originalFlyForwardParameters.masterComponentsRates.filter(
        (rate) => rate.masterComponentSerialNumber !== masterComponentSerialNumber,
      )

      const oldValue = componentRateToChange[activeType]

      if (oldValue !== newValue) {
        dispatch(setSuccessfulReportId({ reportId: '' }))
      }

      const componentRateChanged = {
        ...componentRateToChange,
        [activeType]: value,
      }

      const sortedRates = [componentRateChanged, ...unchangedComponentRates].sort((a, b) => {
        if (a.componentType < b.componentType) return -1
        if (a.componentType > b.componentType) return 1

        if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return -1
        if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return 1

        return 0
      })

      const newFlyForwardParameters: FlyForwardParametersDTO = {
        ...originalFlyForwardParameters,
        masterComponentsRates: sortedRates,
      }

      dispatch(
        setFlyForwardParameters({
          assetId,
          flyForwardParameters: newFlyForwardParameters,
        }),
      )
    },
    [assetId, dispatch],
  )

  const flyToComboBoxItems = [{ value: FlyToType.Custom.toString(), label: getFlyToTypeDisplayName(FlyToType.Custom) }]

  const initialEndDate = initialFlyForwardParameters?.endDate ?? ''

  let flyToType = flyForwardParameters?.flyToType

  const techSpecDate: string = flyForwardParameters?.commonAssetDetails?.assetDetailsSnapshot.dateOfLastTechSpec ?? ''

  if (isValidEndDate(initialEndDate, techSpecDate)) {
    flyToComboBoxItems.push({ value: FlyToType.Redelivery.toString(), label: getFlyToTypeDisplayName(FlyToType.Redelivery) })
  } else {
    flyToType = FlyToType.Custom
  }

  if (isNil(flyForwardParameters)) {
    return <></>
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-x-6">
        <div className="flex gap-x-2">
          <Combobox
            items={flyToComboBoxItems}
            itemLabel="label"
            label="Fly to"
            selectedValues={isNil(flyToType) ? [] : [flyToType.toString()]}
            setSelectedValues={(value: string[]) => {
              if (value.length > 0) {
                const firstValue = parseInt(value[0]) as FlyToType
                onInputChange(flyForwardParameters, 'flyToType', firstValue)
              }
            }}
            className="!w-52"
            hasSearchInput={false}
          />
          <div className="mb-4 mt-auto h-1 w-2 bg-primary" />
          <DatePicker
            key={new Date(flyForwardParameters.endDate).toString()}
            disabled={flyToType === FlyToType.Redelivery}
            label="End"
            inputClassName="w-52"
            setValue={(value?: Date) => {
              if (isNil(value)) {
                onInputChange(flyForwardParameters, 'endDate', flyForwardParameters.endDate)
                return
              }

              const formattedDate = formatDate(value, 'yyyy-MM-dd')
              onInputChange(flyForwardParameters, 'endDate', formattedDate)
            }}
            controlledValue={
              isEmpty(flyForwardParameters.endDate) || !isValidEndDate(flyForwardParameters.endDate, techSpecDate)
                ? undefined
                : new Date(flyForwardParameters.endDate)
            }
          />
        </div>
        <Input
          label={'Build Standard (FC)'}
          controlledValue={String(flyForwardParameters.engineLifeLimitedPartsBuildStandard)}
          setValueAfterValidation={(value) => {
            onInputChange(flyForwardParameters, 'engineLifeLimitedPartsBuildStandard', Number(value))
          }}
          type={InputType.NaturalPositiveNumber}
          inputClassName="min-w-52 w-52 -ml-1 -mr-1 pt-4"
        />

        <Combobox
          items={EnvironmentalImpactTypeOptions}
          itemLabel="label"
          label="Op Environment"
          selectedValues={isNil(opEnvironment) ? [] : [opEnvironment.toString()]}
          setSelectedValues={(value: string[]) => {
            if (value.length > 0) {
              const firstValue = parseInt(value[0]) as EnvironmentalImpactType
              onHandleOpEnvironmentChange(flyForwardParameters, firstValue)
            }
          }}
          className="!w-52"
          hasSearchInput={false}
        />
        <Button
          variant={ButtonVariant.Square}
          size={Size.Small}
          onClick={() =>
            dispatch(
              resetFlyForwardParameters({
                assetId,
              }),
            )
          }
          leftIcon={IconVariant.Refresh}
          className="mt-auto w-9 px-[0.5625rem]"
        />
      </div>
      <p className="mt-4 !text-sm font-semibold">Average monthly utilisation</p>
      <div className="mt-2 flex items-center gap-x-6">
        <Input
          label={'Flight Hours'}
          controlledValue={String(flyForwardParameters.averageMonthlyFlightHours)}
          setValueAfterValidation={(value) => {
            onInputChange(flyForwardParameters, 'averageMonthlyFlightHours', parseInt(value))
          }}
          type={InputType.NaturalPositiveNumber}
          inputClassName="w-52"
        />
        <Input
          label={'Flight Cycles'}
          controlledValue={String(flyForwardParameters.averageMonthlyFlightCycles)}
          setValueAfterValidation={(value) => {
            onInputChange(flyForwardParameters, 'averageMonthlyFlightCycles', parseInt(value))
          }}
          type={InputType.NaturalPositiveNumber}
          inputClassName="w-52"
        />
        <Input
          disabled
          label={'FH/FC Ratio'}
          controlledValue={String(Number(flyForwardParameters.hoursToCyclesRatio ?? 0).toFixed(2))}
          setValueAfterValidation={(value) => {
            onInputChange(flyForwardParameters, 'hoursToCyclesRatio', Number(value).toFixed(2))
          }}
          type={InputType.Decimal}
          inputClassName="w-52"
        />

        {assetType === AssetType.Aircraft && (
          <Input
            label="Average APU Hours"
            controlledValue={String(flyForwardParameters.averageMonthlyAPUHours)}
            setValueAfterValidation={(value) => {
              onInputChange(flyForwardParameters, 'averageMonthlyAPUHours', parseInt(value))
            }}
            type={InputType.NaturalPositiveNumber}
            inputClassName="w-52 "
          />
        )}
      </div>

      <Button
        variant={ButtonVariant.Ghost}
        size={Size.Medium}
        label={showAdditionalParams ? 'Close Parameters' : 'Additional Parameters'}
        className="ml-[56.625rem] mt-2"
        onClick={() => {
          setShowAdditionalParams((prevValue) => !prevValue)
        }}
      />
      <div className={cn(showAdditionalParams ? 'block' : 'hidden')}>
        <ErrorBoundary>
          <EditableEngineLimits
            flyForwardParameters={flyForwardParameters}
            handleEngineLimitsChange={onEngineLimitsChange}
            handleEngineLimitsEditModeChange={onToggleEngineLimitsEditMode}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <EditableRatesParameters
            flyForwardParameters={flyForwardParameters}
            handleRateChange={onRateChange}
            handleRateActive={handleRateActive}
            onEngineHalfLifeLlpReplacementToggle={onEngineHalfLifeLlpReplacementToggle}
            onEngineWaiveRedeliveryConditionsToggle={onEngineWaiveRedeliveryConditionsToggle}
            onAircraftComponentWaiveRedeliveryConditionsToggle={onAircraftComponentWaiveRedeliveryConditionsToggle}
          />
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default FlyForwardParameters
