import { cloneDeep, isNil } from 'lodash'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type EngineUnitInfo } from '@flyward/platform/models/DTOs/EngineUnitInfo'
import { CheckLimitType } from '@flyward/knowledgeBase'
import { Button, ButtonVariant, IconVariant, Input, InputType, Tooltip } from '@flyward/platform/components'
import { computeEngineLimit, getEngineLimitsDependencies } from '../FlyForwardParametersHelpers'

interface IEditableEngineLimits {
  flyForwardParameters: FlyForwardParametersDTO
  handleEngineLimitsChange: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    engineUnitId: string,
    kbProgramId: string,
    eprCheckLimitType: CheckLimitType,
    value: number,
  ) => void
  handleEngineLimitsEditModeChange: (originalFlyForwardParameters: FlyForwardParametersDTO, engineUnitId: string, value: boolean) => void
}

const EditableEngineLimits = ({ flyForwardParameters, handleEngineLimitsChange, handleEngineLimitsEditModeChange }: IEditableEngineLimits) => {
  let enginesDetails: EngineUnitInfo[] = []

  enginesDetails = flyForwardParameters.commonAssetDetails!.engineUnitInfos

  enginesDetails = cloneDeep(enginesDetails).sort((a, b) => {
    if (a.engineUnitSerialNumber < b.engineUnitSerialNumber) return -1
    if (a.engineUnitSerialNumber > b.engineUnitSerialNumber) return 1
    return 0
  })

  const engineKBProgramId = enginesDetails[0]?.assignedKbProgramId

  return (
    <div className="flex flex-col pt-4">
      {enginesDetails.map((engineDetails) => {
        const showTooltip = !engineDetails.areEngineLimitsInManualMode

        const { kbFirstRunLimit, kbMatureLimit, kbDerate, kbRate, kbEnvAdjustment } = getEngineLimitsDependencies(
          engineKBProgramId,
          engineDetails,
          flyForwardParameters,
        )

        const eprChecks = engineDetails.eprKbChecks
        const currentFirstRunLimit = eprChecks.find((c) => !isNil(c.eprCheckLimitType) && c.eprCheckLimitType === CheckLimitType.FirstRun)!
        const currentMatureLimit = eprChecks.find((c) => !isNil(c.eprCheckLimitType) && c.eprCheckLimitType === CheckLimitType.Mature)!

        // initialize backing fields when limits are in computed mode
        if (!engineDetails.areEngineLimitsInManualMode) {
          const { firstRunLimitComputed, matureLimitAmountComputed } = computeEngineLimit(engineKBProgramId, engineDetails, flyForwardParameters)
          if (firstRunLimitComputed !== String(currentFirstRunLimit?.limitAmount)) {
            handleEngineLimitsChange(
              flyForwardParameters,
              engineDetails.engineUnitId,
              engineDetails.assignedKbProgramId,
              CheckLimitType.FirstRun,
              parseFloat(firstRunLimitComputed),
            )
          }

          if (matureLimitAmountComputed !== String(currentMatureLimit?.limitAmount)) {
            handleEngineLimitsChange(
              flyForwardParameters,
              engineDetails.engineUnitId,
              engineDetails.assignedKbProgramId,
              CheckLimitType.Mature,
              parseFloat(matureLimitAmountComputed),
            )
          }
        }

        const FirstRunInput = (
          <Input
            key={`${engineDetails.engineUnitId}-limit-first-run`}
            label={`First Run Limit`}
            disabled={!engineDetails.areEngineLimitsInManualMode}
            controlledValue={currentFirstRunLimit?.limitAmount.toString()}
            setValueAfterValidation={(value) => {
              value !== currentFirstRunLimit?.limitAmount.toString() &&
                handleEngineLimitsChange(
                  flyForwardParameters,
                  engineDetails.engineUnitId,
                  engineDetails.assignedKbProgramId,
                  CheckLimitType.FirstRun,
                  parseFloat(value),
                )
            }}
            type={InputType.NaturalPositiveNumber}
            preventNull={true}
            inputClassName="w-65"
          />
        )

        const MatureRunInput = (
          <Input
            key={`${engineDetails.engineUnitId}-limit-mature`}
            label={`Mature Limit`}
            disabled={!engineDetails.areEngineLimitsInManualMode}
            controlledValue={currentMatureLimit?.limitAmount.toString()}
            setValueAfterValidation={(value) => {
              value !== currentMatureLimit?.limitAmount.toString() &&
                handleEngineLimitsChange(
                  flyForwardParameters,
                  engineDetails.engineUnitId,
                  engineDetails.assignedKbProgramId,
                  CheckLimitType.Mature,
                  parseFloat(value),
                )
            }}
            type={InputType.NaturalPositiveNumber}
            preventNull={true}
            inputClassName="w-65"
          />
        )

        return (
          <div key={engineDetails.assetId + engineDetails.engineUnitId} className="flex items-center gap-x-6 py-4">
            <div>
              <p className="flex pb-2 text-base font-semibold text-gray-700">
                Engine {engineDetails.engineUnitSerialNumber} Limits
                <Button
                  className="pl-2"
                  variant={ButtonVariant.Ghost}
                  leftIcon={engineDetails.areEngineLimitsInManualMode ? IconVariant.Refresh : IconVariant.Edit}
                  onClick={() => {
                    handleEngineLimitsEditModeChange(flyForwardParameters, engineDetails.engineUnitId, !engineDetails.areEngineLimitsInManualMode)
                  }}
                />
              </p>
              <div className="flex gap-x-6">
                {showTooltip ? (
                  <Tooltip
                    content={FirstRunInput}
                    tooltipContent={`${kbFirstRunLimit?.limitAmount} * (1 + ${kbDerate}/100) * (1 + ${kbRate}/100) * (1 + ${kbEnvAdjustment}/100)`}
                  />
                ) : (
                  FirstRunInput
                )}
                {showTooltip ? (
                  <Tooltip
                    content={MatureRunInput}
                    tooltipContent={`${kbMatureLimit?.limitAmount} * (1 + ${kbDerate}/100) * (1 + ${kbRate}/100) * (1 + ${kbEnvAdjustment}/100)`}
                  />
                ) : (
                  MatureRunInput
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export { EditableEngineLimits }
