/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { cn, NumberDisplay, NumberDisplayType, Tooltip } from '@flyward/platform/components'
import { getEventChangeReasonDisplayName, ReportDisplayType, UtilizationUnitsDisplayShort, type IEventViewDetailDto } from '@flyward/platform/models'
import { isNil } from 'lodash'

interface IEventDetailPopoverProps {
  reportDisplayType: ReportDisplayType

  componentEvent: IEventViewDetailDto
  tdStyle: string
  borderAndTextStyle: string
  isSiblingComponent?: boolean
}

const EventRow = ({ reportDisplayType, componentEvent, tdStyle, borderAndTextStyle, isSiblingComponent = false }: IEventDetailPopoverProps) => {
  return (
    <tr className={cn('flex w-full bg-row-even', componentEvent.lineStyle, isSiblingComponent && 'bg-row-odd italic')}>
      <td
        className={cn(
          'basis-2/36 overflow-hidden text-ellipsis whitespace-nowrap text-nowrap',
          tdStyle,
          borderAndTextStyle,
          reportDisplayType === ReportDisplayType.AssetReport && 'hidden',
        )}
      >
        {componentEvent.assetSerialNumber}
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        {componentEvent.masterComponentSerialNumber === componentEvent.componentSerialNumber ? (
          `${componentEvent.masterComponentSerialNumber}`
        ) : (
          <Tooltip content={componentEvent.masterComponentSerialNumber} tooltipContent={`LLP SN: ${componentEvent.componentSerialNumber}`} />
        )}
      </td>
      <td
        className={cn(
          'overflow-hidden text-ellipsis whitespace-nowrap text-nowrap',
          tdStyle,
          borderAndTextStyle,
          reportDisplayType === ReportDisplayType.AssetReport ? 'basis-5/36' : 'basis-4/36',
        )}
      >
        {componentEvent.eventType}
      </td>
      <td className={cn(tdStyle, borderAndTextStyle, reportDisplayType === ReportDisplayType.AssetReport ? 'basis-4/36' : 'basis-3/36')}>
        {isSiblingComponent ? 'Not In Scope' : getEventChangeReasonDisplayName(componentEvent.reason)}
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        <NumberDisplay value={componentEvent.tsn} />
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        <NumberDisplay value={componentEvent.csn} />
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        <NumberDisplay value={componentEvent.kbLimitReference} />
        {!isNil(componentEvent.kbLimitReference) && (
          <span className="pl-1">{UtilizationUnitsDisplayShort(componentEvent.defaultUtilizationUnit)}</span>
        )}
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        <NumberDisplay value={componentEvent.remainingUtilizationUntilLimit} />
        {!isNil(componentEvent.remainingUtilizationUntilLimit) && (
          <span className="pl-1">{UtilizationUnitsDisplayShort(componentEvent.defaultUtilizationUnit)}</span>
        )}
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        <NumberDisplay value={componentEvent.tslpr} />
      </td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}>
        <NumberDisplay value={componentEvent.cslpr} />
      </td>
      <td className={cn('basis-3/36 text-end', tdStyle, borderAndTextStyle)}>
        <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={componentEvent.mrBalance} />
      </td>
      <td className={cn('basis-3/36 text-end', tdStyle, borderAndTextStyle)}>
        <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={isSiblingComponent ? undefined : componentEvent.eventCost} />
      </td>
      <td className={cn('basis-3/36 text-end', tdStyle, borderAndTextStyle)}>
        <Tooltip
          content={
            <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={isSiblingComponent ? undefined : componentEvent.cashFlowOut} />
          }
          tooltipContent={`Delay in months: ${componentEvent.monthsDelay}`}
        />
      </td>
      <td className={cn('basis-3/36 text-end', tdStyle, borderAndTextStyle)}>
        {componentEvent.lessorContribution !== undefined ? (
          <Tooltip
            content={
              <NumberDisplay
                className="text-highlight-lessor-contribution"
                displayType={NumberDisplayType.CurrencyRounded}
                value={isSiblingComponent ? undefined : componentEvent.lessorContribution}
              />
            }
            tooltipContent={`Delay in months: ${componentEvent.monthsDelay}`}
          />
        ) : (
          '-'
        )}
      </td>
      <td className={cn('basis-3/36 text-end', tdStyle, borderAndTextStyle)}>
        <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={isSiblingComponent ? undefined : componentEvent.eventShortfall} />
      </td>
    </tr>
  )
}

export default EventRow
