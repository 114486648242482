import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { App } from './main-app/App'
import { reportHandler, reportWebVitals } from './main-app/services'
import { ErrorBoundary } from './platform'
import './main.css'
import { AppProvider as MUIAppProvider } from '@toolpad/core/AppProvider'
import { NotificationsProvider as MUINotificationsProvider } from '@toolpad/core/useNotifications'
import { theme } from './theme'
import { ThemeProvider } from '@mui/material'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MUIAppProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <MUINotificationsProvider>
          {/* ErrorBoundary is ta the root of the app
        so that the user never gets to see an empty white page
        in case an error occurs */}
          <ErrorBoundary>
            <IntlProvider locale="en-US">
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </IntlProvider>
          </ErrorBoundary>
        </MUINotificationsProvider>
      </ThemeProvider>
    </MUIAppProvider>
  </React.StrictMode>,
)

void reportWebVitals(reportHandler)
