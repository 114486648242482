import { CnForm, ComponentContainer, ComponentTabs, AsyncErrorBoundary } from '@flyward/platform'
import { LandingGearDtoSchema, type LandingGearDto } from '../../../../../models'
import { LGTechnicalTab } from './LGTechnicalTab'
import { LGContractualTab } from './LGContractualTab'
import { useEffect, useRef, useState } from 'react'
import { useUpdateLandingGearMutation } from '@flyward/assets/store/api'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LandingGearLessorContributionsTab } from './LandingGearLessorContributionsTab'
import { LGDetails } from './LGDetails/LGDetails'

interface ILandingGearDetailsProps {
  landingGear: LandingGearDto
}

export const LandingGearDetails = ({ landingGear }: ILandingGearDetailsProps): React.ReactElement<ILandingGearDetailsProps> => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)

  const [currentTab, setCurrentTab] = useState<string>('Technical')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }

  const [updateLandingGear] = useUpdateLandingGearMutation()

  const form = useForm<LandingGearDto>({
    defaultValues: landingGear,
    values: landingGear,
    resolver: zodResolver(LandingGearDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    register: registerLandingGearFormField,
    handleSubmit: handleLandingGearUpdate,
    formState,
    getValues: getLandingGearFormValues,
    setValue: setLandingGearFormValue,
    control: landingGearFormControl,
    trigger: triggerLandingGearValidation,
  } = form

  const landingGearFormValues: LandingGearDto = getLandingGearFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('LG formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerLandingGearValidation()
  }, [isValid, triggerLandingGearValidation])

  useEffect(() => {
    registerFormRef({ id: [landingGearFormValues.assetId, landingGearFormValues.componentId], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, landingGearFormValues, isDirty, isValid])

  const onUpdateLandingGear: SubmitHandler<LandingGearDto> = (data) => {
    updateLandingGear({ assetId: landingGear.assetId, landingGear: data })
  }

  const tabs = {
    Technical: {
      content: (
        <AsyncErrorBoundary>
          <LGTechnicalTab
            key={landingGearFormValues.componentId}
            component={landingGearFormValues}
            registerFormField={registerLandingGearFormField}
            setFormValue={setLandingGearFormValue}
            formControl={landingGearFormControl}
            formState={formState}
          />
        </AsyncErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <AsyncErrorBoundary>
          <LGContractualTab component={landingGearFormValues} formControl={landingGearFormControl} />
        </AsyncErrorBoundary>
      ),
    },
    'Lessor Contribution': {
      content: (
        <AsyncErrorBoundary>
          <LandingGearLessorContributionsTab
            key={landingGearFormValues.componentId}
            component={landingGearFormValues}
            registerFormField={registerLandingGearFormField}
            setFormValue={setLandingGearFormValue}
            formControl={landingGearFormControl}
            formState={formState}
          />
        </AsyncErrorBoundary>
      ),
    },
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleLandingGearUpdate(onUpdateLandingGear)}>
        <ComponentContainer className="p-6">
          <div className="flex w-full flex-col">
            <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Landing Gear</p>
            <div className="flex flex-col gap-y-2">
              <LGDetails component={landingGear} setFormValue={setLandingGearFormValue} formControl={landingGearFormControl} />
              <ComponentTabs
                tabTriggerHeight="h-9"
                tabTriggerLabelClassName="h-8"
                tabs={tabs}
                controlledActiveTab={currentTab}
                onTabChanged={onTabChanged}
              />
            </div>
          </div>
        </ComponentContainer>
        <input type="submit" className="hidden" ref={submitRef} />
      </form>
    </CnForm>
  )
}
